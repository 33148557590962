import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { IApiPhotoFolderFilter, IApiPhotoFolderOrderBy, IApiUpdatePhotoInput, IApiUploadTypes } from './../../../../shared/modules/graphql/types/types';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, KeyValueDiffers } from '@angular/core';
import { readAndCompressImage } from 'browser-image-resizer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap, filter, switchMap, switchAll, toArray, map, debounceTime, pairwise, finalize } from 'rxjs/operators';
import { IApiInvestigation, IApiPhoto, IApiPhotoFilter, IApiPhotoFolder, IApiPhotoFolderFilterType } from 'src/app/shared/modules/graphql/types/types';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { AuthService, PhotoFolderService, PhotoService, S3Service } from 'src/app/shared/services';
import { CdkDragDrop, CdkDragEnter, moveItemInArray } from "@angular/cdk/drag-drop";
import { investigationPhotosCreate, investigationPhotosUpdate, investigationPhotosDelete } from "src/app/shared/helpers/auth-config/investigations.config";
import { MatDialog } from '@angular/material/dialog';
import { InvestigationPhotosModalComponent } from '../investigation-photos-modal/investigation-photos-modal.component';
import { photosAllAssigned } from 'src/app/shared/helpers/auth-config/photos.config';
import { cloneDeep, isEqual } from 'lodash';
import { Subscription, BehaviorSubject } from 'rxjs';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { FileSelectModalComponent } from 'src/app/shared/components/file-select-modal/file-select-modal.component';
import { imageMimeType } from 'src/app/shared/helpers/helper';
import { environment } from 'src/environments/environment';
import { FileInterface, uploadFilesPayLoad } from 'src/app/shared/services/s3/s3-service';

// Interfaces to allow "selected" individual photos
interface ISelectablePhoto extends IApiPhoto {
  selected: boolean;
}

interface ISelectablePhotoFolder extends IApiPhotoFolder {
  Photos: Array<ISelectablePhoto>;
}

@UntilDestroy()
@Component({
  selector: 'app-investigation-photos',
  templateUrl: './investigation-photos.component.html',
  styleUrls: ['./investigation-photos.component.scss']
})
export class InvestigationPhotosComponent implements OnInit {

  public authConfig = {
    investigationPhotosCreate,
    investigationPhotosUpdate,
    investigationPhotosDelete,
    photosAllAssigned
  };

  @Output() changed = new EventEmitter<ISelectablePhoto[]>();
  @Output() reload = new EventEmitter<boolean>();

  @ViewChild('fileUploadHighRes') fileUploadHighRes: ElementRef<HTMLElement>;
  @ViewChild('fileUpload') fileUpload: ElementRef<HTMLElement>;

  public photoReorder = new Subject<any>();
  public photoUpdate = new BehaviorSubject<IApiPhoto>({
    id: null,
    uri: null,
    s3Uri: null,
    fileSizeKB: null,
    fileName: null,
    caption: ''
  });

  public showEditFolder = false;
  public showNewFolder = false;
  public showReorder = false;
  public visiblePhotosFilter: boolean = null;

  public authUserId: string = null;

  private _investigation: IApiInvestigation;
  @Input() public set investigation(value: IApiInvestigation) {
    this._investigation = value;

    this._folderFilter = value ? {
      type: IApiPhotoFolderFilterType.Investigation,
      value: value.id
    } : {};
  }

  public get hasLowResPhotos() {
    return this.folders?.find(({ Photos }) => Photos?.find((e) => e?.lowResS3Uri));
  }

  // list of folders to select from
  public folders: ISelectablePhotoFolder[] = [];

  private _filters: IApiPhotoFilter[] = [];
  private _folderFilter: IApiPhotoFolderFilter;

  private _selectedFolder: ISelectablePhotoFolder = null;
  public get selectedFolder(): ISelectablePhotoFolder {
    return this._selectedFolder;
  }

  public set selectedFolder(value: ISelectablePhotoFolder) {
    this._photosSnapshot = value && value.Photos ? cloneDeep(value.Photos) : [];
    this._selectedFolder = value;
  }

  public get usedPhotoLength() {
    return this.selectedFolder.Photos.filter(d => d.isUsed).length || 0;
  }

  public get photoLength() {
    return this.selectedFolder.Photos.length || 0;
  }

  private _photosSnapshot: ISelectablePhoto[] = null;
  public get photosSnapshot(): ISelectablePhoto[] {
    return this._photosSnapshot;
  }

  public set photosSnapshot(value: ISelectablePhoto[]) {
    this._photosSnapshot = value;
  }

  public get filters() {
    return this._filters;
  }

  public set filters(val) {
    this._filters = val;
  }

  // public selectedImages: string[] = [];
  public showFilename = false;
  public timerSubscription: Subscription;
  public processingSave = false;
  public newUploadAction = [
    { text: 'High-Res (recommended)' },
    { text: 'Low-Res (poor connection)' }
  ];
  private MARK_AS_USED = "Mark as Used";
  private MARK_AS_UNUSED = "Mark as Unused";
  private DELETE = "Delete";

  public photoListUpdateAction = [
    { text: this.MARK_AS_USED },
    { text: this.MARK_AS_UNUSED },
  ];
  public photoListDeleteAction = [
    { text: this.DELETE },
  ]

  public photoListAction = [
    { text: this.MARK_AS_USED },
    { text: this.MARK_AS_UNUSED },
    { text: this.DELETE },
  ];
  public reloadPhotos = false;
  constructor(
    private photoFolderService: PhotoFolderService,
    private photoService: PhotoService,
    private notifications: NotificationsService,
    public loader: LoaderService,
    private dialog: MatDialog,
    private auth: AuthService,
    private differs: KeyValueDiffers,
    private dialogService: DialogService,
    private s3Service: S3Service,
  ) {
  }

  // Delete single & selected photos
  public deletePhotos(photos: ISelectablePhoto[]) {
    const message = photos.length > 1 ? 's' : '';
    this.notifications.confirm(`Are you sure you want to delete the selected photo${message}?`, "Hang on a second!").afterClosed().pipe(
      filter(r => r),
      switchMap(() => this.loader.show$(
        this.photoService.deleteBulk(photos.map((photo) => photo.id))
      ))
    ).subscribe((res) => this.getFolders());
  }

  // Visible photos filter (All, Used, Unused)

  public get visiblePhotos() {
    switch (this.visiblePhotosFilter) {
      case true:
        return this.selectedFolder.Photos.filter(d => d.isUsed);
      case false:
        return this.selectedFolder.Photos.filter(d => !d.isUsed);
      default:
        return this.selectedFolder.Photos;
    }
  }

  // Deselect "Selected" photos on filter (All, Used, Unused) change
  public changePhotosFilter(changeFilter) {
    this.selectedFolder.Photos.forEach(d => d.selected = false);
    this.visiblePhotosFilter = changeFilter;
  }

  // Find selected photos
  public get selectedPhotos() {
    return this.selectedFolder.Photos.filter(d => d.selected);
  }

  // "Select All" visible photos scheckbox
  public checkAll(event): void {
    this.visiblePhotos.forEach(d => d.selected = event.checked);
  }

  public async togglePhotoUse(photos: ISelectablePhoto[], newUsedVal: boolean) {

    // First update photo index
    for (const photo of photos) {

      const currIndex = this.selectedFolder.Photos.findIndex((obj) => obj.id === photo.id);
      const newIndex = newUsedVal ? this.usedPhotoLength : this.selectedFolder.Photos.length - 1;

      // Update here instead of the component for multiselect
      this.selectedFolder.Photos[currIndex].isUsed = newUsedVal;

      moveItemInArray(
        this.selectedFolder.Photos,
        currIndex,
        newIndex
      );

    }

    // Then update photo position
    this.updatePhotoPosition();

    // Then call bulk update
    this.reloadPhotos = false;
    this.photoReorder.next();
  }

  public setSelectedFolderName(name: string) {
    this.loader.show$(
      this.photoFolderService.update({
        id: this.selectedFolder.id,
        name
      }).pipe(
        this.notifications.catchAlertPipe("Error modifying Folder; please try again.")
      )
    ).subscribe(() => {
      this.selectedFolder.name = name;
      this.showEditFolder = false;
    });
  }

  public createFolder(name: string) {
    if (name?.trim()?.length === 0) {
      this.notifications.error("Folder name can not be left blank.");
      return;
    }
    this.loader.show$(
      this.photoFolderService.add({
        name,
        InvestigationId: this._investigation.id
      }).pipe(
        this.notifications.catchAlertPipe("Error creating Folder; please try again."),
        this.notifications.alertPipe("Photo Folder successfully added!")
      )
    ).subscribe(folder => {
      this.folders.push(folder);
      this.selectedFolder = folder;
      this.showNewFolder = false;
    });
  }

  public async onUploadSuccess(filesUploaded: any) {
    const batchSize = 75;
    const fileChunks = [];
    for (let i = 0; i < filesUploaded.length; i += batchSize) {
      fileChunks.push(filesUploaded.slice(i, i + batchSize));
    }

    let newPhotoPosition = this.selectedFolder?.Photos.length;
    let outerIndex = 0;
    for (const chunk of fileChunks) {
      await this.loader.show$(
        this.photoService.addBulk(chunk.map(({ key, cropFile, file, width, height }, innerIndex: number) => {
          newPhotoPosition++;
          return {
            uri: '',
            s3Uri: key,
            fileSizeKB: cropFile?.file?.size || file?.size || 0,
            fileName: cropFile?.file?.name || file?.name || '',
            width: cropFile?.file?.width || width || 0,
            height: cropFile?.file?.height || height || 0,
            caption: "",
            FolderId: this.selectedFolder.id,
            isEvidence: false,
            isUsed: false,
            position: newPhotoPosition,
            createExpense: outerIndex < 1 && innerIndex < 1 && this.folders.every(folder => !folder.Photos.length) ? true : false
          };
        }))
      ).toPromise();
      outerIndex++;
    }

    this.getFolders();
    this.reload.emit(true);
  }


  private async updatePhotoSingle(data: IApiUpdatePhotoInput, reload = false) {
    if (!data.id) return;
    await this.photoService.update(data).toPromise();
    if (reload) {
      this.getFolders();
      this.reload.emit(true);
    }
  }

  public async updatePhotoBulk(reload = true) {
    // Bulk Save Photos
    const batchLimit = 75;
    let batchProcess = [];
    const updatePhotos = [];

    if (!this.selectedFolder || !this.selectedFolder.Photos) return;

    // Compare current photo to snapshot to see if there is a change
    this.selectedFolder.Photos.forEach((currentPhoto) => {

      const originalPhoto = this.photosSnapshot.find((obj) => obj.id === currentPhoto.id);
      const compareOriginal: IApiUpdatePhotoInput = {
        id: originalPhoto.id,
        uri: originalPhoto.uri,
        s3Uri: originalPhoto.s3Uri,
        caption: originalPhoto.caption,
        isEvidence: originalPhoto.isEvidence,
        isUsed: originalPhoto.isUsed,
        position: originalPhoto.position,
        height: originalPhoto.height,
        width: originalPhoto.width,
        fileSizeKB: originalPhoto.fileSizeKB,
        fileName: originalPhoto.fileName,
        lowResUri: originalPhoto.lowResUri,
        lowResS3Uri: originalPhoto.lowResS3Uri,
        FolderId: this.selectedFolder.id,
      };

      const compareCurrent: IApiUpdatePhotoInput = {
        id: currentPhoto.id,
        uri: currentPhoto.uri,
        s3Uri: currentPhoto.s3Uri,
        caption: currentPhoto.caption,
        isEvidence: currentPhoto.isEvidence,
        isUsed: currentPhoto.isUsed,
        position: currentPhoto.position,
        height: currentPhoto.height,
        width: currentPhoto.width,
        fileSizeKB: currentPhoto.fileSizeKB,
        fileName: currentPhoto.fileName,
        lowResUri: currentPhoto.lowResUri,
        lowResS3Uri: currentPhoto.lowResS3Uri,
        FolderId: this.selectedFolder.id
      };

      if (!isEqual(compareOriginal, compareCurrent) || reload === false) updatePhotos.push(compareCurrent);
    });

    if (updatePhotos.length) {

      // Break into segments of 100 photos to prevent errors of payload too large
      for (const photo of updatePhotos) {
        batchProcess.push(photo);
        if (batchProcess.length >= batchLimit) {
          await this.loader.show$(this.photoService.updateBulk(batchProcess)).toPromise();
          batchProcess = [];
        }
      }

      // Update remaining photos
      await this.loader.show$(this.photoService.updateBulk(batchProcess)).toPromise();
      batchProcess = [];

      // Emit changes and reload
      if (reload) {
        this.getFolders();
        this.reload.emit(true);
      }
    }
  }

  public replaceLowResPhotos(filesUploaded: FileInterface[] = []) {
    filesUploaded.forEach(({ key, file, width, height }) => {
      const currIndex = this.selectedFolder.Photos.findIndex((obj) => obj.fileName === file.name);
      this.selectedFolder.Photos[currIndex].fileSizeKB = file.size;
      this.selectedFolder.Photos[currIndex].width = width;
      this.selectedFolder.Photos[currIndex].height = height;
      this.selectedFolder.Photos[currIndex].s3Uri = key;
      this.selectedFolder.Photos[currIndex].lowResS3Uri = null;
    });
    this.updatePhotoBulk();
  }

  public ngOnInit(): void {
    this.getFolders();
    this.auth.authenticatedUser.pipe(untilDestroyed(this)).subscribe((u) => this.authUserId = u.id);

    // Follow photo reorder events
    this.photoReorder.pipe(
      untilDestroyed(this),
      debounceTime(500),
      tap(() => this.updatePhotoBulk(this.reloadPhotos))
    ).subscribe();

    // Follow photo update events
    this.photoUpdate
      .pipe(
        pairwise(),
        untilDestroyed(this),
        tap((arr) => {
          // Compare here so we can catch a change in photo if user quickly switches input before debounce completes
          if (arr[0]?.id && arr[1]?.id && (arr[0]?.id !== arr[1]?.id)) {
            const { id, caption, isEvidence, isUsed, position } = arr[0];
            return this.updatePhotoSingle({ id, caption, isEvidence, isUsed, position });
          }
        }),
        debounceTime(1000),
        tap((arr) => {
          const { id, caption, isEvidence, isUsed, position } = arr[1];
          this.updatePhotoSingle({ id, caption, isEvidence, isUsed, position });
        })
      ).subscribe();
  }

  public getFolders() {
    this.loader.show$(
      this.photoFolderService.get([this._folderFilter], { orderBy: IApiPhotoFolderOrderBy.CreatedAt, sortOrder: SortOrder.ASC }).pipe(
        unwrapConnection(),
        !this.selectedFolder
          ? tap(([first]) => this.selectedFolder = first)
          : tap(folders => this.selectedFolder = folders.find(f => f.id === this.selectedFolder.id))
      )
    ).pipe(
      switchAll(),
      map((folder: ISelectablePhotoFolder) => {
        return folder;
      }),
      toArray()
    ).subscribe((data) => {
      this.folders = data;
      this.showNewFolder = !this.folders.length;
    });
  }

  private updatePhotoPosition() {
    this.selectedFolder.Photos.forEach((currentPhoto, index) => {
      currentPhoto.position = index;
    });
  }

  public reorder(event: CdkDragDrop<number>) {
    this.updatePhotoPosition();
    this.reloadPhotos = false;
    this.photoReorder.next();
  }

  entered(event: CdkDragEnter) {
    moveItemInArray(this.selectedFolder.Photos, event.item.data, event.container.data);
  }

  public async fileChange({ target }, fileControl: HTMLInputElement) {
    if (!target || !target.files.length) return;
    const fileLimit = 750;
    if (target.files.length > fileLimit) {
      this.notifications.notify(
        `The limit for uploading ${fileLimit} files, please select fewer files and try again.`,
        { icon: false, style: "warning" }
      );
      return;
    }
    const resizedFiles: FileInterface[] = [];
    try {
      // Note: Not performant but due to the limitations of readAndCompressImage / native File type, a Promise.all() with target.files.length number of files did not work
      // it simply silently failed - so this is syncronous

      // Use this loader for "for" loop since this isn't an observable
      this.loader.show();
      for (const file of target.files) {
        const { name, size, type } = file;
        const resized = await readAndCompressImage(file, {
          quality: 0.7,
          maxWidth: 640,
          maxHeight: 480
        });
        const fileObj = new File([resized], name);
        resizedFiles.push({ file: fileObj, cropFile: null, height: 480, width: 640, id: null, isImage: true, objectURL: null, preview: null });
      }

      const uploadPayLoad: uploadFilesPayLoad = {
        files: resizedFiles,
        FolderId: this.selectedFolder.id,
        InvestigationId: this._investigation.id,
        maxParallelUploads: 3,
        type: IApiUploadTypes.Photos
      };

      this.s3Service
        .uploadFiles(uploadPayLoad)
        .pipe(
          finalize(async () => {
            const batchSize = 75;
            const fileChunks = [];
            for (let i = 0; i < resizedFiles.length; i += batchSize) {
              fileChunks.push(resizedFiles.slice(i, i + batchSize));
            }
            let outerIndex = 0;
            for (const chunk of fileChunks) {
              await this.photoService.addBulk(chunk.map(({ key, file, position }, innerIndex: number) => {
                return {
                  uri: '',
                  lowResS3Uri: key,
                  s3Uri: key,
                  fileSizeKB: file?.size || 0,
                  fileName: file?.name || '',
                  width: 480,
                  height: 640,
                  caption: "",
                  FolderId: this.selectedFolder.id,
                  isEvidence: false,
                  isUsed: false,
                  position: (resizedFiles.length + this.photoLength),
                  createExpense: outerIndex < 1 && innerIndex < 1 && this.folders.every(folder => !folder.Photos.length) ? true : false
                };
              })).pipe(
                this.notifications.catchAlertPipe("Error adding low-res photo!")
              ).toPromise();
              outerIndex++;
            };

            this.loader.hide();
            this.notifications.notify("Low-Res photo uploaded!");
            fileControl.value = null;
            this.getFolders();
          })
        ).subscribe(
          (progress: { id: string; percentage: number } | null) => { },
          (error) => {
            console.error("Error uploading files", error);
          }
        );
    }
    catch (exc) {
      console.error(exc);
      this.notifications.alert("Error resizing image to low-resolution. Please try again.");
    }
  }

  public openFolderModal(highRes = true) {
    const dialogRef = this.dialog.open(InvestigationPhotosModalComponent, {
      width: '50%',
      data: this.folders,
    }).afterClosed().pipe(
      filter((v) => !!v)
    ).subscribe((folder) => {
      if (folder.id) this.selectedFolder = this.folders.find((obj) => obj.id === folder.id);
      else this.createFolder(folder.name);
      // Open Image Upload Modal
      // this.fileUploadHighRes.nativeElement.click();
      if (highRes) this.fileSelector();
      else this.fileUpload.nativeElement.click();
      // Tried opening modal this way, but having trouble passing in call back functions.
      // Keeping here incase we need to refactor later.
      // this.filestackService.picker({...this.pickerOptions}).open();
    });
  }

  public fileSelector(uploadType = 'HIGH_RESOLUTION') {
    const dialog: DialogRef = this.dialogService.open({
      content: FileSelectModalComponent,
      width: 600,
      maxWidth: 600,
      maxHeight: 670,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as FileSelectModalComponent;
    dialogInstance.data = {
      InvestigationId: this._investigation.id,
      FolderId: this.selectedFolder.id,
      type: IApiUploadTypes.Photos,
      restrictions: imageMimeType,
      multiple: true,
      limit: 750,
    };
    dialog.result.subscribe((res: any) => {
      if (res?.length && res) {
        if (uploadType === 'HIGH_RESOLUTION') {
          this.onUploadSuccess(res);
        } else {
          this.replaceLowResPhotos(res);
        }
      }
    });
  }

  public photoAction(event) {
    switch (event?.text) {
      case this.MARK_AS_USED:
        this.togglePhotoUse(this.selectedPhotos, true)
        break;
      case this.MARK_AS_UNUSED:
        this.togglePhotoUse(this.selectedPhotos, false)
        break;
      case this.DELETE:
        this.deletePhotos(this.selectedPhotos)
        break;
      default:
        break;
    }
  }

  public onError(event: any, s3Uri: string, height = 1250) {
    s3Uri = encodeURIComponent(s3Uri);
    const originalImage = environment.cloudFrontS3 + '/' + s3Uri;
    let resizeImageUrl;
    if (environment.env === 'LOCAL') {
      resizeImageUrl = `${environment.resizeApiURL}/resize/test?key=${s3Uri}&height=${height}`;
    } else {
      resizeImageUrl = `${environment.resizeApiURL}/resize?key=${s3Uri}&height=${height}`;
    }
    if (event.target.src === resizeImageUrl) {
      const imgElement = event.target as HTMLImageElement;
      imgElement.src = originalImage;
    } else {
      const imgElement = event.target as HTMLImageElement;
      imgElement.src = resizeImageUrl;
    }
    return;
  }

  public onImageLoad(data){
    data['loaded'] = true;
  }

}
