import { Component } from '@angular/core';
import { Columns, IInvestigationTableConfig, ReportTableType } from '../report-table/report-table.component';

@Component({
  selector: 'app-investigation-report',
  templateUrl: './investigation-report.component.html',
  styleUrls: ['./investigation-report.component.scss']
})
export class InvestigationReportComponent {

  constructor() { }

  public tableConfig: IInvestigationTableConfig = {
    type: ReportTableType.investigation,
    displayedColumns: [
      Columns.nefcoNumber,
      Columns.status,
      Columns.lossDate,
      Columns.insured,
      Columns.dateReceived,
      Columns.investigatorOnSceneDate,
      Columns.daysToInsuredContacted,
      Columns.daysToOnScene,
      Columns.daysToWrittenSummary,
      Columns.docuSketch,
      Columns.client,
      Columns.clientEmail,
      Columns.billTo,
      Columns.billToEmail,
      Columns.riskType,
      Columns.address,
      Columns.city,
      Columns.state,
      Columns.postal,
      Columns.claimNumber,
      Columns.policyNumber,
      Columns.investigator,
      Columns.billedInvoiceTotal,
      Columns.billingRule,
    ],
    viewType: null,
    startWithCount: false
  };

}
