<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationDocumentsUpdate"
  #investigationDocumentsUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationReportsUpdate" #investigationReportsUpdate="appEnforcePermission">
</div>
<!-- Permissions -->
<section>
  <div class="page-filters">
    <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" [cols]="['repeat(5,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <div class="kendo-formfield left-search-icon">
          <kendo-label [for]="search" text="Search"></kendo-label>
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-textbox #search [(ngModel)]="searchValue" (valueChange)="setFilters($event, filterTypes.Search)"
              placeholder="Search" fillMode="outline" [clearButton]="false"
              [value]="filterValue(filterTypes.Search || null)">
            </kendo-textbox>
          </div>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <app-user-select-kendo [userViewFilter]="userViewFilter" [userId]="filterValue(filterTypes.User)" label="Search"
          placeholder="Search by staff member" [includeInactive]="false"
          (selectionChanged)="setFilters($event?.id, filterTypes.User)">
        </app-user-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <kendo-label [for]="reportList" text="Report"></kendo-label>
          <kendo-dropdownlist #reportList [data]="reportTypes" textField="name" valueField="id" fillMode="outline"
            [valuePrimitive]="true" [defaultItem]="{ id: null, name: 'All' }"
            [value]="filterValue(filterTypes.Type || null)"
            (selectionChange)="setFilters($event?.id ? $event?.name : null, filterTypes.Type)"
            [popupSettings]="{appendTo: 'component', animate: false, width: 250}">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span *ngIf="dataItem?.name === 'All'" class="placeholder">
                {{ dataItem.name }}
              </span>
              <span *ngIf="dataItem?.name !== 'All'" class="not-placeholder">
                {{ dataItem.name }}
              </span>
            </ng-template>
            <ng-template kendoDropDownListNoDataTemplate>
            </ng-template>
          </kendo-dropdownlist>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item class="filter-actions" [colSpan]="2" [col]="0" [row]="0">
        <div class="applied-filter mr-16">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="filterTable()">Apply
              Filters</kendo-button>
          </div>
        </div>
        <div class="clear-filter mt-5">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearFilter()">Clear
              All</kendo-button>
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </div>
</section>
<section>
  <kendo-grid class="pending-request" [data]="{
      data: tableData,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }" [pageSize]="pageOptions?.limit" [skip]="pageOptions?.offset" [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }" (pageChange)="pageChange($event)" [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)"
    [navigatable]="false">

    <kendo-grid-column field="CREATED_AT" title="Created" [width]="20" [sortable]="true" [headerClass]="'sort-header'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="f-600">
          {{ dataItem?.createdAt | customDate : "MM/dd/yyyy" }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="nefcoNumber" title="ID" [width]="20" class="id-column" [sortable]="false"
      [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/investigations/', dataItem?.Investigation?.id]" target="_blank" tel="" class="text-decoration">
          {{ dataItem?.Investigation?.nefcoNumber || "Unassigned" }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="PRIORITY" title="Priority" [width]="15" [sortable]="true" [headerClass]="'sort-header'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-badge class="{{
            'fw600 ' +
              (dataItem?.Investigation?.Priority?.name === 'Low' ||
              dataItem?.Investigation?.Priority?.name === 'Medium'
                ? 'fcolor'
                : '')
          }}" position="none" size="medium" rounded="full" themeColor="{{
            dataItem?.Investigation?.Priority?.name === 'Low'
              ? 'tertiary'
              : dataItem?.Investigation?.Priority?.name === 'Medium'
              ? 'warning'
              : dataItem?.Investigation?.Priority?.name === 'High'
              ? 'error'
              : ''
          }}">{{ dataItem?.Investigation?.Priority?.name }}</kendo-badge>
        <ng-container *ngIf="!dataItem?.Investigation?.Priority?.name">-</ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="LossAddress" title="Location" [width]="30" [sortable]="false" [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem?.Investigation?.LossAddress; else locationElse">
          <ng-container *ngIf="dataItem?.Investigation?.LossAddress?.address1">
            <span class="text-overflow">{{
              dataItem?.Investigation?.LossAddress?.address1
              }}</span>
            <br />
          </ng-container>
          {{ dataItem?.Investigation?.LossAddress?.city }},
          {{ dataItem?.Investigation?.LossAddress?.state }}
          {{ dataItem?.Investigation?.LossAddress?.postal }}
        </ng-container>
        <ng-template #locationElse>-</ng-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="Client" title="Client" [width]="30" [sortable]="false" [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="text-overflow" *ngIf="dataItem?.Investigation?.Client">{{ dataItem.Investigation.Client | fullName
          }}<br /></span>
        <span class="text-overflow" *ngIf="dataItem?.Investigation?.ClientBranch?.Company?.name">{{
          dataItem?.Investigation?.ClientBranch?.Company?.name }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="Type" title="Report" [width]="25" [sortable]="false" [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="link" (click)="openInOffice365(dataItem)" [innerHTML]="dataItem.Type.name"></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="CreatedBy" title="Created By" [width]="25" [sortable]="false" [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="text-overflow">{{ dataItem.CreatedBy | fullName }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="SUBMITTED_AT" title="Submitted" [width]="20" [sortable]="true"
      [headerClass]="'sort-header'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem?.Status?.createdAt">{{dataItem?.Status?.createdAt | customDate : 'MM/dd/yyyy' }}</span>
        <span *ngIf="!dataItem?.Status?.createdAt">-</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="id" title="Assigned" [width]="30" [sortable]="false" [headerClass]="'nocursor'"
      *ngIf="config.type === types.TechReview">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container
          *ngFor="let staff of dataItem?.Investigation?.InvestigationStaff | investigationStaffRole : investigationRoleNames.TechReviewer : true">
          <span *ngIf="staff" class="text-overflow">{{ staff | fullName }}<br /></span>
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="EDITOR" title="Assigned" [width]="30" [sortable]="true" [headerClass]="'sort-header'"
      *ngIf="config.type === types.EditorReview">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem?.Editor" class="text-overflow">{{ dataItem?.Editor | fullName }}<br /></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="Action" title="Action" [width]="config.type === types.ReadyForEditor ? 25 : 10"
      [sortable]="false" [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="config.type !== types.ReadyForEditor; else readyForEditorButton">
          <kendo-dropdownbutton [data]="(config.type === types.EditorReview && dataItem.Status?.status === IApiInvestigationReportStatus.EditorReview)
            ? editorReviewActions : (config.type === types.EditorReview && dataItem.Status?.status === IApiInvestigationReportStatus.ClientReview ? clientReviewActions : ((config.type === types.TechReview) ? techReviewActions : [{text: 'Indicate Cause'}, {text: 'Send Back'}, {text: 'Approve'}]))
            " themeColor="primary" fillMode="link" buttonClass="grid-action-items" iconClass="fa-solid fa-ellipsis"
            (itemClick)="itemSelected($event, dataItem)" [popupSettings]="{
              appendTo: 'component',
              animate: false,
              width: 250,
              popupClass: 'grid-action-items-menu'
            }">
          </kendo-dropdownbutton>
        </ng-container>
        <ng-template #readyForEditorButton>
          <button kendoButton themeColor="primary" fillMode="link" (click)="assignToMe(dataItem);"
            *ngIf="investigationDocumentsUpdate?.enabled && investigationReportsUpdate?.enabled">Assign to me</button>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</section>
