import {
  IApiInvestigation, IApiUpdateInvestigationInput
} from 'src/app/shared/modules/graphql/types/types';

export class Investigation {
  /**
   * Converts an Investigation into IApiUpdateInvestigationInput
   */
  public static getUpdateInputObject(obj: IApiInvestigation): IApiUpdateInvestigationInput {
    const {
      id,
      lossDate,
      receivedDate,
      Detail,
      LossAddress,
      SceneContact,
      InvestigationAddress,
      Client,
      Priority: { id: PriorityId },
      RiskType,
      BillTo,
      Company,
      LinkedInvestigations = [],
      billingInstructions,
      onHold,
      isIncendiary,
      isRejected,
      docusketchRequested,
      isXactimate,
      isSymbility,
      timezone
    } = obj;

    return {
      id,
      lossDate,
      Detail,
      ClientId: Client ? Client.id : null,
      PriorityId,
      RiskTypeId: RiskType ? RiskType.id : null,
      SceneContactId: SceneContact ? SceneContact.id : null,
      receivedDate,
      BillToId: BillTo ? BillTo.id : null,
      CompanyId: Company ? Company.id : null,
      InvestigationAddress,
      LossAddress,
      LinkedInvestigationIds: LinkedInvestigations.map(({ id: _id }) => _id),
      billingInstructions,
      onHold,
      isIncendiary,
      isRejected,
      docusketchRequested,
      isXactimate,
      isSymbility,
      timezone
    };
  }
}
