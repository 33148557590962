import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import dayjs from 'dayjs';
import {
  investigationScheduledDateUpdateAll,
  investigationScheduledDateUpdateOwn
} from "src/app/shared/helpers/auth-config/investigations.config";
import { IApiInvestigation, IApiInvestigationRoleNames, IApiInvestigationStaff, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { AuthService, InsuredPartyService } from 'src/app/shared/services';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);
@Component({
  selector: 'app-investigation-edit-insured-contacted',
  templateUrl: './investigation-edit-insured-contacted.component.html',
  styleUrls: ['./investigation-edit-insured-contacted.component.scss']
})
export class InvestigationEditInsuredContactedComponent extends DialogContentBase implements OnInit {

  @Input() opened: boolean = false;
  @Input() minWidth: number = 600;
  @Input() width: number = 600;
  @Input() headerIcon: string = null;
  @Input() investigationStaff: IApiInvestigationStaff[] = null;
  @Input() investigation: IApiInvestigation = null;
  @ViewChild("frm", { static: false }) frm: NgForm;

  public authConfig = {
    investigationScheduledDateUpdateAll,
    investigationScheduledDateUpdateOwn
  }

  public staffList = [];

  public model = {
    staff: null,
    contactedDate: null
  }

  public currentDate = new Date();
  private currentUser: IApiUser;
  private hasAllPermission: boolean = false;
  public receivedDate: Date = null;
  constructor(
    public dialog: DialogRef,
    private auth: AuthService,
    private loader: LoaderService,
    private notification: NotificationsService,
    private insuredPartyService: InsuredPartyService
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    this.receivedDate = new Date(this.investigation.receivedDate);
    this.auth.authenticatedUser.subscribe((u) => {
      this.currentUser = u;
      this.auth.hasCategoryPermission(this.authConfig.investigationScheduledDateUpdateAll.category, this.authConfig.investigationScheduledDateUpdateAll.appliedPermissions).subscribe((result) => {
        if (result === true) {
          this.hasAllPermission = true;
        }
      })
    }
    );

    this.staffList = this.staffSort(this.investigationStaff);
  }

  close(result = false): void {
    this.dialog.close(result);
  }

  public staffSort(list: IApiInvestigationStaff[]) {
    if (!list || !list.length) return [];
    let list_ = [...list];
    list_ = list?.filter((user) => user?.Role?.title === IApiInvestigationRoleNames.Investigator);
    list_.map(a => {
      a['userName'] = `${a?.User?.firstName} ${a?.User?.lastName}`;
      a['userId'] = a?.User?.id;
    });
    return list_.sort((a, b) => a.User?.lastName > b.User.lastName ? 1 : -1);
  }

  public submit() {
    if (!this.frm.valid) {
      return;
    }
    const date = dayjs(this.model.contactedDate).tz(this.investigation.timezone, true).toISOString();
    const input = {
      InvestigationId: this.investigation.id,
      UserId: this.hasAllPermission === false ? this.currentUser.id : this.model.staff,
      date: date
    };
    this.loader.show$(
      this.insuredPartyService.addInsuredContact(input),
    ).pipe(
      this.notification.catchAlertPipe(),
    ).subscribe((result) => {
      this.notification.notify("Insured contact date saved successfully");
      this.close(result);
    })
  }

}
