<app-breadcrumbs></app-breadcrumbs>
<section class="mat-typography">
  <h2>
    {{ id ? "Modify" : "Create" }} Investigation
    <span *ngIf="investigation?.nefcoNumber"
      ># {{ investigation.nefcoNumber }}</span
    >
    <button
      mat-button
      class="investigation-button float-right ml-3"
      [disabled]="!id"
      routerLink="/investigations/{{ id }}"
      [ngClass]="{ 'investigation-button-disabled': !id }"
    >
      Go To Investigation Detail
    </button>
  </h2>

  <div class="content-wrapper">
    <!-- Main Form -->
    <form #form="ngForm" appDebouncedChange [appDirtyFormNotify]="form">
      <div class="flexContainer inv-info">
        <!-- recievedDate-->
        <div class="equal align-self-center">
          <span class="display-block" *ngIf="id">
            <strong>Status:</strong> &nbsp; {{ investigation.status }}
          </span>
          <p class="date-time">
            Received:
            {{ model.receivedDate | customDate : "MM-dd-yyyy hh:mm:ss a" }}
          </p>
          <p *ngIf="investigation.deletedAt" class="date-time">
            Cancelled:
            {{ investigation.deletedAt | customDate : "MM-dd-yyyy hh:mm:ss a" }}
          </p>
        </div>

        <!-- Priority select -->
        <div class="equal align-self-center">
          <app-investigation-priorities-select
            name="priorities"
            [(ngModel)]="model.PriorityId"
            required
          ></app-investigation-priorities-select>
        </div>

        <div class="btn-group align-self-center">
          <div class="equal">
            <mat-button-toggle-group class="text-right" id="show-trigger">
              <!-- onHold -->
              <mat-slide-toggle
                name="onHold"
                [(ngModel)]="model.onHold"
                color="primary"
                >On Hold</mat-slide-toggle
              >

              <!-- rejected -->
              <!-- TODO hide once investigation has been created based on a value TBD ... has detail? has someone assigned? etc. -->
              <mat-slide-toggle
                name="isRejected"
                (change)="rejectCase($event)"
                *ngIf="id"
                color="primary"
              >
                Case Rejected
              </mat-slide-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <!-- save indicator -->
        <div class="align-self-center">
          <div class="with-icon d-flex-center">
            <ng-container *ngIf="id; else failedIcon">
              <mat-icon matSuffix class="inline-icon" color="accent"
                >check_circle</mat-icon
              >
              {{ model["updatedAt"] | customDate : "MM-dd-yyyy hh:mm:ss a" }}
            </ng-container>
            <ng-template #failedIcon>
              <mat-icon matSuffix class="inline-icon" color="warn"
                >warning</mat-icon
              >
              Not Saved
            </ng-template>
          </div>
        </div>
      </div>

      <mat-card class="mat-elevation-z8">
        <!--  title -->
        <div class="flexContainer">
          <div class="equal">
            <mat-card-header>
              <mat-card-title>Investigation Information</mat-card-title>
            </mat-card-header>
          </div>

          <div class="equal">
            <!-- <ng-container *ngIf="!id; else menuContainer">
              <button
                mat-raised-button
                color="accent"
                class="float-right"
                [disabled]="
                  !form.dirty ||
                  !form.valid ||
                  !model.LossAddress ||
                  (!model.Detail.policyNumber && !model.Detail.claimNumber)
                "
                (click)="save()"
              >
                <mat-icon>{{ id ? "cloud_upload" : "library_add" }}</mat-icon>
                &nbsp;
                {{ id ? "Update" : "Create Investigation" }}
              </button>
            </ng-container> -->
            <ng-container *ngIf="id">
              <button
                mat-raised-button
                color="accent"
                class="float-right"
                [disabled]="!form.dirty || !form.valid"
                [mat-menu-trigger-for]="actionMenu"
              >
                <mat-icon>cloud_upload</mat-icon> &nbsp; Update
              </button>
              <ng-container *ngIf="investigation.deletedAt; else cancelButton">
                <button
                  kendoButton
                  themeColor="light"
                  (click)="openRejectReactivateModel('reactivate')"
                  class="kendo-btn float-right mr-2"
                  fillMode="solid"
                >
                  Reactivate Investigation
                </button>
              </ng-container>
              <ng-template #cancelButton>
                <button
                  kendoButton
                  themeColor="primary"
                  (click)="openRejectReactivateModel('reject')"
                  class="reject-investigation-btn kendo-btn float-right mr-2"
                  fillMode="solid"
                >
                  Reject Investigation
                </button>
                <!-- <button
                  mat-button
                  (click)="cancelInvestigation()"
                  class="float-right"
                >
                  <mat-icon color="accent">cancel</mat-icon>
                </button> -->
              </ng-template>
              <mat-menu #actionMenu>
                <button mat-menu-item (click)="save()">Update</button>
                <button mat-menu-item (click)="save(true)">
                  Update &amp; Close
                </button>
              </mat-menu>
            </ng-container>
          </div>
        </div>

        <div class="row">
          <div class="column col-1-input-fields">
            <!-- client and billing info-->
            <app-investigation-client
              [investigation]="this.baseModel"
              (selected)="setClientInfo($event)"
              [resetForm]="resetForm"
            ></app-investigation-client>

            <input
              type="hidden"
              name="clientId"
              [(ngModel)]="this.model.ClientId"
            />
            <input
              type="hidden"
              name="ClientBranchId"
              [(ngModel)]="this.model.ClientBranchId"
            />
            <input
              type="hidden"
              name="billToId"
              [(ngModel)]="this.model.BillToId"
              required
            />
            <input
              type="hidden"
              name="billToBranchId"
              [(ngModel)]="this.model.BillToBranchId"
            />
            <input
              type="hidden"
              name="companyId"
              [(ngModel)]="this.model.CompanyId"
            />

            <br />

            <div class="flexContainer">
              <!-- lossDate -->
              <div class="equal">
                <app-datepicker
                  [(ngModel)]="lossDate"
                  name="lossDate"
                  [label]="'Loss Date'"
                  [isLossDate]="true"
                ></app-datepicker>
              </div>

              <!-- lossTime -->
              <div class="equal">
                <!-- TODO - allow direct input-->
                <app-timepicker
                  name="lossTime"
                  [disabled]="!lossDate"
                  [(ngModel)]="lossTime"
                  [label]="'Loss Time'"
                ></app-timepicker>
              </div>
            </div>

            <!-- RiskDescription -->
            <div class="flexContainer">
              <div class="equal description">
                <mat-form-field class="description">
                  <mat-label> Risk Description </mat-label>
                  <textarea
                    matInput
                    name="riskDescription"
                    [(ngModel)]="model.Detail.riskDescription"
                  ></textarea>
                </mat-form-field>
              </div>
               <!-- RiskType -->
               <div class="equal">
                <app-risk-types-select
                  name="riskTypeId"
                  [(ngModel)]="model.RiskTypeId"
                  required
                ></app-risk-types-select>
              </div>
            </div>

            <div class="flexContainer">
              <div class="equal mr-2 mb-2">
                <mat-form-field class="w-100">
                  <mat-label>Policy #</mat-label>
                  <input
                    matInput
                    [(ngModel)]="model.Detail.policyNumber"
                    name="policyNumber"
                    #policyNumber="ngModel"
                    appNoWhiteSpace
                  />
                  <mat-hint
                    align="start"
                    *ngIf="
                      !model.Detail.policyNumber && !model.Detail.claimNumber
                    "
                    class="text-danger"
                    >A Policy # OR Claim # must be entered</mat-hint
                  >
                </mat-form-field>
              </div>
              <div class="equal">
                <mat-form-field class="w-100">
                  <mat-label>Claim #</mat-label>
                  <input
                    matInput
                    [(ngModel)]="model.Detail.claimNumber"
                    name="claimNumber"
                    #claimNumber="ngModel"
                    appNoWhiteSpace
                  />
                </mat-form-field>
              </div>
            </div>

            <app-notes
              name="detailNotes"
              title="Investigation Notes:"
              [(ngModel)]="model.Detail.notes"
              >message</app-notes
            >
          </div>

          <div class="column col-2-input-fields">
            <!-- Loss Address -->
            <div class="flexContainer">
              <mat-card-content>
                <app-address-autocomplete
                  *ngIf="!!mapsLoaded"
                  [address]="model.LossAddress"
                  (setAddress)="setLossAddress($event)"
                  (setTimezone)="setTimezone($event)"
                  [loadAPI]="false"
                  [resetForm]="resetForm"
                ></app-address-autocomplete>
                <!-- <app-nefco-address name="lossAddress" [(ngModel)]="model.LossAddress" required></app-nefco-address> -->
              </mat-card-content>
            </div>
            <kendo-gridlayout [rows]="['auto']" [cols]="['100%']">
              <kendo-gridlayout-item>
                <kendo-formfield>
                  <kendo-label class="asterisk" [for]="timezone" text="Timezone"></kendo-label>
                  <kendo-dropdownlist #timezone [data]="timeZones" name="timezone" textField="key" valueField="value"
                    [valuePrimitive]="true" [(ngModel)]="model.timezone" fillMode="outline"
                    [defaultItem]="{value: '', key: 'Select timezone'}" [popupSettings]="{appendTo: 'component', animate: false}">
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                      <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem.key }} </span>
                      <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem.key }} </span>
                    </ng-template>
                  </kendo-dropdownlist>
                </kendo-formfield>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
            <br />

            <div class="flexContainer">
              <div class="equal">
                <mat-card-content>
                  <p>
                    <span class="font-weight-bold">Note:</span> Mapping features
                    only apply when the address search is used. Custom addresses
                    will not return proximity results.
                  </p>
                </mat-card-content>
              </div>
              <button
                mat-stroked-button
                (click)="checkLossAddress()"
                [disabled]="!model.LossAddress || !model.LossAddress.longitude"
              >
                <mat-icon
                  matSuffix
                  class="inline-icon"
                  [color]="addressCheckComplete ? 'accent' : 'warn'"
                  >{{
                    addressCheckComplete ? "check_circle" : "warning"
                  }}</mat-icon
                >
                Check
              </button>
            </div>

            <br />

            <!-- Investigation Address / Scene Exam Address -->
            <div class="flexContainer">
              <div class="equal">
                <mat-card-content>
                  Scene Exam Address:
                  <mat-checkbox
                    color="accent"
                    name="isLossSameAddress"
                    [(ngModel)]="isInvestigationAddressSameAsLoss"
                    >Same as loss address</mat-checkbox
                  >
                </mat-card-content>
              </div>

              <!-- TODO -->
              <ng-container *ngIf="!isInvestigationAddressSameAsLoss">
                <app-address-autocomplete
                  *ngIf="!!mapsLoaded"
                  [address]="model.InvestigationAddress"
                  (setAddress)="setInvestigationAddress($event)"
                  [loadAPI]="false"
                ></app-address-autocomplete>
                <!-- <app-nefco-address name="investigationAddress" [(ngModel)]="model.InvestigationAddress" [touched]="form.touched"></app-nefco-address> -->
                <br />
              </ng-container>
            </div>

            <br />

            <div class="flexContainer">
              <div class="equal">
                <mat-card-content>
                  Docusketch Imaging:
                  <mat-checkbox color="accent" name="docusketch" [(ngModel)]="model.docusketchRequested">
                    <p class="mb-0"><span class="font-weight-bold">Does the client require Docusketch imaging?</span></p>
                  </mat-checkbox>
                  <kendo-formhint class="mt-0" style="margin-left: 187px">* Additional fees apply</kendo-formhint>
                </mat-card-content>
              </div>
            </div>

            <div class="flexContainer" *ngIf="model?.docusketchRequested">
              <div class="equal mr-2 mb-2">
                <mat-card-content class="w-100">
                  Please Indicate:
                  <mat-checkbox class="mr-16" style="margin-left: 34px;" color="accent" name="isXactimate" [(ngModel)]="model.isXactimate">
                    <p class="mb-0"><span class="font-weight-bold">Xactimate</span></p>
                  </mat-checkbox>
                  <mat-checkbox color="accent" name="isSymbility" [(ngModel)]="model.isSymbility">
                    <p class="mb-0"><span class="font-weight-bold">Symbility</span></p>
                  </mat-checkbox>
                  <kendo-formhint class="mt-0 text-danger" style="margin-left: 164px;" *ngIf="(!model.isXactimate && !model.isSymbility) || (model.isXactimate && model.isSymbility)">
                    Please fill out either the Xactimate or Symbility
                  </kendo-formhint>
                </mat-card-content>
              </div>
            </div>

            <!-- specialInstructions -->
            <div class="flexContainer">
              <div class="equal">
                <mat-form-field class="description">
                  <mat-label> Special Billing Instructions </mat-label>
                  <textarea
                    matInput
                    name="specialInstructions"
                    [(ngModel)]="model.Detail.specialInstructions"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <kendo-card width="100%" *ngIf="!id">
        <kendo-card-body>
          <h2 class="mb-24">Vehicles</h2>
          <h6 class="mb-24" *ngIf="vehiclesModel?.length > 0">New Vehicle:</h6>

          <div class="text-center" *ngIf="!vehiclesModel?.length">
            <button kendoButton iconClass="fa-regular fa-plus" themeColor="light" fillMode="solid"
              (click)="addVehicle()">Add Vehicle</button>
          </div>

          <section *ngIf="vehiclesModel?.length > 0">
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['18%', '18%', '18%', '18%', '18%', '3%']">
              <ng-container *ngFor="let item of vehiclesModel; let index = index">
                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label class="asterisk" text="Make"></kendo-label>
                    <kendo-textbox name="make_{{index}}" #make="ngModel" [(ngModel)]="item.make" fillMode="outline" placeholder="Enter make"
                      required appNoWhiteSpace></kendo-textbox>
                    <kendo-formerror *ngIf="!make?.valid && (make?.dirty || make?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="make?.errors?.required">Make cannot be left blank</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label class="asterisk" text="Model"></kendo-label>
                    <kendo-textbox name="model_{{index}}" #model="ngModel" [(ngModel)]="item.model" fillMode="outline"
                      placeholder="Enter model" required appNoWhiteSpace></kendo-textbox>
                    <kendo-formerror *ngIf="!model?.valid && (model?.dirty || model?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="model?.errors?.required">model cannot be left blank</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label class="asterisk" text="Year"></kendo-label>
                    <kendo-textbox name="year_{{index}}" #year="ngModel" [(ngModel)]="item.year" fillMode="outline" placeholder="Enter year"
                      required appNoWhiteSpace></kendo-textbox>
                    <kendo-formerror *ngIf="!year?.valid && (year?.dirty || year?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="year?.errors?.required">Year cannot be left blank</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label class="asterisk" text="Vin #"></kendo-label>
                    <kendo-textbox name="vinNumber_{{index}}" #vinNumber="ngModel" [(ngModel)]="item.vinNumber" fillMode="outline"
                      placeholder="Enter vin #" required appNoWhiteSpace></kendo-textbox>
                    <kendo-formerror *ngIf="!vinNumber?.valid && (vinNumber?.dirty || vinNumber?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="vinNumber?.errors?.required">Vin cannot be left blank</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item>
                  <kendo-formfield>
                    <kendo-label text="Stock"></kendo-label>
                    <kendo-textbox name="stockNumber_{{index}}" #stockNumber="ngModel" [(ngModel)]="item.stockNumber" fillMode="outline"
                      placeholder="Enter stock #" appNoWhiteSpace></kendo-textbox>
                    <kendo-formerror *ngIf="!stockNumber?.valid && (stockNumber?.dirty || stockNumber?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="stockNumber?.errors?.required">Stock cannot be left blank</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="actions">
                  <span class="k-icon k-i-minus-outline" style="margin-top: 30px;" (click)="removeVehicle(index)"></span>
                </kendo-gridlayout-item>
              </ng-container>
            </kendo-gridlayout>
            <button kendoButton class="mr-24" icon="plus-circle" themeColor="primary" fillMode="link" (click)="addVehicle()">Add Additional Vehicle</button>
            <br />
            <br />
            <app-notes
              name="vehicleNotes"
              title="Vehicle Notes:"
              [(ngModel)]="model.Detail.vehicleNotes"
              >message</app-notes
            >
          </section>
        </kendo-card-body>
      </kendo-card>

      <kendo-card width="100%" *ngIf="!id">
        <kendo-card-body>
          <h2 class="mb-24">Party</h2>
          <h6 class="mb-24" *ngIf="enableAddParty">Add Party</h6>

          <div class="text-center" *ngIf="!enableAddParty">
            <button kendoButton iconClass="fa-regular fa-plus" themeColor="light" fillMode="solid"
              (click)="enableAddParty = true">Add Party</button>
          </div>
          <section *ngIf="enableAddParty">
              <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['25%', '16%', '16%', '16%', '16%', '5%']">
                <kendo-gridlayout-item [colSpan]="1">
                  <kendo-formfield>
                    <kendo-label text="Contact Role"></kendo-label>
                    <kendo-dropdownlist name="role" #role="ngModel" [data]="roles" textField="name" valueField="id"  fillMode="outline"
                      [valuePrimitive]="true" [defaultItem]="{id: '', name: 'Select contact role'}" [(ngModel)]="roleId"
                      [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                          <span *ngIf="dataItem?.name === 'Select contact role'" class="placeholder"> {{ dataItem?.name }} </span>
                          <span *ngIf="dataItem?.name !== 'Select contact role'" class="not-placeholder"> {{ dataItem?.name }} </span>
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [colSpan]="5">
                  <div>
                    <button class="mt-24" kendoButton themeColor="primary" fillMode="solid"
                    (click)="addContact()" style="width: 110px;" [disabled]="!roleId">Add Contact</button>
                  </div>
                </kendo-gridlayout-item>
              </kendo-gridlayout>

              <app-notes
                name="partyNotes"
                title="Party Notes:"
                [(ngModel)]="model.Detail.partyNotes"
                >message</app-notes
              >

              <form #frm="ngForm" *ngIf="parties?.length" class="mt-16">
                <mat-accordion>
                  <mat-expansion-panel *ngFor="let party of parties; let index = index;">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{ party.Role?.name }}
                      </mat-panel-title>
                      <mat-panel-description>
                        {{ party.Contact?.insuredName }} {{ party.Contact | fullName }}
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="float-right">
                      <!-- TODO - support setting another contact (other than first) as the Insured -->
                      <button
                        mat-flat-button
                        color="warn"
                        (click)="removeParty(index)"
                      >
                        <mat-icon matSuffix>remove_circle</mat-icon> Remove
                      </button>
                      <button
                        mat-flat-button
                        color="primary"
                        (click)="editContact(party.Contact.id)"
                        class="ml-3"
                      >
                        <mat-icon matSuffix>edit</mat-icon> Edit Contact
                      </button>
                    </div>
                    <div class="clearfix"></div>
                    <!-- Displaying Contact Information -->
                    <div class="row info-div">
                      <div class="col-12">
                        <h3>Contact Information</h3>
                      </div>
                      <div class="col-3" *ngIf="party.Contact?.insuredName">
                        <p class="mat-small mat-caption">Insured Name</p>
                        <p [innerHTML]="party.Contact.insuredName"></p>
                      </div>
                      <div class="col-3">
                        <p class="mat-small mat-caption">First Name</p>
                        <p [innerHTML]="party.Contact.firstName"></p>
                      </div>
                      <div class="col-3">
                        <p class="mat-small mat-caption">Last Name</p>
                        <p [innerHTML]="party.Contact.lastName"></p>
                      </div>
                      <div class="col-3">
                        <p class="mat-small mat-caption">Nickname</p>
                        <p [innerHTML]="party.Contact.nickName"></p>
                      </div>
                      <div class="col-3">
                        <p class="mat-small mat-caption">Suffix</p>
                        <p [innerHTML]="party.Contact.suffix"></p>
                      </div>
                      <div class="col-3">
                        <p
                          class="mat-small mat-caption"
                          *ngIf="party.Contact.policyNumber"
                        >
                          Policy #
                        </p>
                        <p [innerHTML]="party.Contact.policyNumber"></p>
                      </div>
                      <div class="col-3">
                        <p
                          class="mat-small mat-caption"
                          *ngIf="party.Contact.claimNumber"
                        >
                          Claim #
                        </p>
                        <p [innerHTML]="party.Contact.claimNumber"></p>
                      </div>
                      <div class="col-6">
                        <p class="mat-small mat-caption">Phones</p>
                        <app-email-phone-view
                          *ngFor="let phone of party.Contact.Phones"
                          [phone]="phone"
                        ></app-email-phone-view>
                      </div>
                      <div class="col-6">
                        <p class="mat-small mat-caption">Emails</p>
                        <app-email-phone-view
                          *ngFor="let email of party.Contact.Emails"
                          [email]="email"
                        ></app-email-phone-view>
                      </div>
                    </div>
                    <!-- Displaying Addresses -->
                    <div class="row info-div">
                      <div class="col-12">
                        <h3>Address</h3>
                      </div>
                      <div class="col-3" *ngFor="let address of party.Contact.Addresses">
                        <app-address-view [address]="address"></app-address-view>
                      </div>
                    </div>
                    <!-- Displaying Company/Branch -->
                    <div class="row info-div">
                      <div class="col-12">
                        <h3>Company Information</h3>
                      </div>
                      <ng-container *ngFor="let branch of party.Contact.Branches">
                        <div class="col-3">
                          <p class="mat-small mat-caption">Company Name</p>
                          <p [innerHTML]="branch.Company.name"></p>
                        </div>
                        <div class="col-3">
                          <p class="mat-small mat-caption">Branch Name</p>
                          <p [innerHTML]="branch.name"></p>
                        </div>
                        <div class="col-2">
                          <p class="mat-small mat-caption">Branch Phones</p>
                          <app-email-phone-view
                            *ngFor="let phone of branch.Phones"
                            [phone]="phone"
                          ></app-email-phone-view>
                        </div>
                        <div class="col-2">
                          <p class="mat-small mat-caption">Branch Emails</p>
                          <app-email-phone-view
                            *ngFor="let email of branch.Emails"
                            [email]="email"
                          ></app-email-phone-view>
                        </div>
                        <div class="col-2">
                          <p class="mat-small mat-caption">Branch Addresses</p>
                          <app-address-view
                            *ngFor="let address of branch.Addresses"
                            [address]="address"
                          ></app-address-view>
                        </div>
                      </ng-container>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </form>
          </section>
        </kendo-card-body>
      </kendo-card>

      <kendo-card width="100%" *ngIf="!id">
        <kendo-card-body>
          <h2 class="mb-24">Linked Investigation</h2>
          <h6 class="mb-24" *ngIf="linkedInvestigationModel?.length > 0">Add Linked Investigation</h6>

          <div class="text-center" *ngIf="!linkedInvestigationModel?.length">
            <button kendoButton iconClass="fa-regular fa-plus" themeColor="light" fillMode="solid"
              (click)="addLinkedInvestigation()">Add Linked Investigation</button>
          </div>

          <section *ngIf="linkedInvestigationModel?.length > 0">
            <form #formLinedInv="ngForm" >
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['25%', '16%', '16%', '16%', '16%', '5%']">
              <ng-container *ngFor="let item of linkedInvestigationModel; let index = index">
                <kendo-gridlayout-item [colSpan]="1">
                  <kendo-formfield>
                    <kendo-label text="Investigation ID"></kendo-label>
                    <kendo-textbox name="id_{{index}}" #id="ngModel" [(ngModel)]="item.id" fillMode="outline" (ngModelChange)="updateInvalid(index)"></kendo-textbox>
                    <kendo-formerror *ngIf="!id?.valid && (id?.dirty || id?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span *ngIf="id?.errors?.isInvalidId">Looks like this ID is invalid. Please select a different ID.</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item style="display: flex;" [colSpan]="5">
                  <div>
                    <button class="mt-24" kendoButton themeColor="primary" fillMode="solid"
                    (click)="createLinkInvestigation(item.id, index)" style="width: 110px;" [disabled]="!item.id">Validate ID</button>
                  </div>
                  <div>
                    <button class="mt-24 ml-16" kendoButton themeColor="light" fillMode="solid"
                    (click)="removeLinkedInvestigation(index)" style="width: 110px;">Remove</button>
                  </div>
                </kendo-gridlayout-item>
              </ng-container>
            </kendo-gridlayout>
            </form>

            <button kendoButton class="mr-24" icon="plus-circle" themeColor="primary" fillMode="link" (click)="addLinkedInvestigation()">Add Additional Linked Investigation</button>
            <br />
            <br />
            <app-notes
              name="linkedNotes"
              title="Linked Investigation Notes:"
              [(ngModel)]="model.Detail.linkedInvestigationNotes"
              >message</app-notes
            >
          </section>
        </kendo-card-body>
      </kendo-card>
    </form>

    <!-- Add Sections Buttons -->
    <div class="flexContainer" *ngIf="id">
      <!-- Billing Information Button -->
      <div class="equal">
        <mat-card-actions align="end">
          <button
            mat-button
            class="add-button"
            (click)="showBilling = !showBilling"
          >
            <mat-icon matSuffix>add</mat-icon> Add Billing Information
          </button>
        </mat-card-actions>
      </div>

      <!-- Linked Investigations Button -->
      <div class="equal">
        <mat-card-actions align="center">
          <button
            mat-button
            class="add-button"
            (click)="showLinkedInvestigations = !showLinkedInvestigations"
          >
            <mat-icon matSuffix>add</mat-icon> Add Linked Investigations
          </button>
        </mat-card-actions>
      </div>

      <!-- Vehicles Button -->
      <div class="equal">
        <mat-card-actions align="start">
          <button
            mat-button
            class="add-button"
            (click)="showVehicles = !showVehicles"
          >
            <mat-icon matSuffix>add</mat-icon> Add Vehicles
          </button>
        </mat-card-actions>
      </div>
    </div>

    <!-- Billing Card -->
    <div *ngIf="showBilling || model.Detail?.budgetAmount">
      <mat-card class="mat-elevation-z8">
        <mat-card-header>
          <mat-card-title>Investigation Billing Information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <button
            mat-raised-button
            color="primary"
            class="save-billing-detail"
            [disabled]="!bForm.dirty || !bForm.valid"
            (click)="saveBillingDetail(bForm)"
          >
            <mat-icon>save</mat-icon> &nbsp; Save Billing Information
          </button>
          <form
            #bForm="ngForm"
            class="d-flex flex-row pt-2 clearfix"
            [appDirtyFormNotify]="bForm"
          >
            <!--
              // TODO - what is this?
              <mat-card-content>
                <mat-checkbox #budgetSpecified color="primary"> Budget Specified</mat-checkbox>
              </mat-card-content>
              -->
            <mat-form-field class="budget-amount mt-4 mr-5">
              <mat-label>$ Budget Amount</mat-label>
              <input
                matInput
                type="text"
                name="budgetAmount"
                [(ngModel)]="model.Detail.budgetAmount"
              />
            </mat-form-field>
            <mat-form-field class="ml-5">
              <mat-label>Investigation Billing Instructions </mat-label>
              <textarea
                matInput
                name="billingInstructions"
                [(ngModel)]="model.billingInstructions"
                rows="4"
              ></textarea>
            </mat-form-field>
          </form>
          <app-notes
            name="billingNotes"
            title="Billing Instructions Notes:"
            [(ngModel)]="model.Detail.billingInstructionNotes"
            (ngModelChange)="save()"
            >message</app-notes
          >
        </mat-card-content>
      </mat-card>
      <br />
    </div>

    <!-- Linked Investigations Card -->
    <div *ngIf="showLinkedInvestigations">
      <mat-card class="mat-elevation-z8">
        <mat-card-header class="d-flex justify-content-between">
          <button
            mat-raised-button
            class="float-right"
            type="button"
            (click)="checkLossAddress()"
          >
            <mat-icon>location_on</mat-icon>
            Proximity Check
          </button>
          <mat-card-title>Linked Investigations</mat-card-title>
        </mat-card-header>
        <div
          class="p-15"
          *ngFor="
            let linkedInvestigation of baseModel.LinkedInvestigations;
            let i = index
          "
        >
          <mat-chip-list>
            <mat-chip [removable]="true" (removed)="unlinkInvestigation(i)">
              {{ linkedInvestigation.nefcoNumber || linkedInvestigation.id }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
        <div>
          <div class="column col-1-billing-fields">
            <div class="flexContainer parties-info">
              <div class="equal">
                <mat-form-field>
                  <mat-label>Investigation #</mat-label>
                  <input matInput type="text" #linkCtl />
                </mat-form-field>
                <mat-card-actions>
                  <button
                    mat-button
                    class=""
                    (click)="linkInvestigation(linkCtl)"
                    [disabled]="!linkCtl.value?.length"
                  >
                    <mat-icon matSuffix>add</mat-icon> Add Linked Investigation
                  </button>
                </mat-card-actions>
              </div>
            </div>
          </div>
        </div>
        <app-notes
          name="linkedNotes"
          title="Linked Investigation Notes:"
          [(ngModel)]="model.Detail.linkedInvestigationNotes"
          (ngModelChange)="save()"
          >message</app-notes
        >
      </mat-card>
      <br />
    </div>

    <!-- Vehicles Card -->
    <div *ngIf="showVehicles">
      <mat-card class="mat-elevation-z8">
        <!-- Vehicles Header and Content Title -->
        <div
          class="flexContainer vehicles-info"
          *ngIf="id; else vehicleMoreInfo"
        >
          <div class="equal">
            <mat-card-header>
              <mat-card-title>Vehicles</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <!-- <div class="flexContainer vehicles-info"> -->
              <ng-container *ngFor="let vehicle of vehicles; let i = index">
                <h3 class="mb-1 mt-2">Vehicle {{ i + 1 }}:</h3>
                <app-investigation-vehicle
                  [investigationId]="id"
                  [vehicle]="vehicle"
                  (changed)="loadVehicles()"
                ></app-investigation-vehicle> </ng-container
              ><br />
              <h3 class="mb-1 mt-3 mr-3">New Vehicle:</h3>
              <app-investigation-vehicle
                [investigationId]="id"
                (changed)="loadVehicles()"
              ></app-investigation-vehicle>
              <!-- </div> -->
            </mat-card-content>
            <app-notes
              name="vehicleNotes"
              title="Vehicle Notes:"
              [(ngModel)]="model.Detail.vehicleNotes"
              (ngModelChange)="save()"
              >message</app-notes
            >
          </div>
        </div>
        <ng-template #vehicleMoreInfo>
          <h2>More Detail Needed</h2>
          <p>
            To add vehicles more primary information is needed above. This
            section will automatically become available.
          </p>
        </ng-template>
      </mat-card>
    </div>

    <br />

    <!-- Parties Card -->
    <div *ngIf="id">
      <mat-card class="mat-elevation-z8">
        <app-investigation-party
          [investigation]="investigation"
        ></app-investigation-party>
        <app-notes
          name="partyNotes"
          title="Party Notes:"
          [(ngModel)]="model.Detail.partyNotes"
          (ngModelChange)="save()"
          >message</app-notes
        >
      </mat-card>
    </div>

    <br />

    <!-- Investigation Staff -->
    <div *ngIf="id">
      <mat-card class="mat-elevation-z8">
        <mat-card-header>
          <mat-card-title>Staff</mat-card-title>
        </mat-card-header>
        <app-investigation-assignments-container
          [staff]="baseModel?.InvestigationStaff"
          [address]="model.LossAddress"
          [investigation]="investigation"
          [clientId]="model.ClientId"
          (updated)="reloadInvestigation()"
        ></app-investigation-assignments-container>
        <br />
        <!-- This is outside the main form, so we have to trigger a save after updating -->
        <app-notes
          name="staffNotes"
          title="Investigation Staff Notes:"
          [(ngModel)]="model.Detail.staffNotes"
          (ngModelChange)="save()"
          >message</app-notes
        >
      </mat-card>
    </div>
  </div>
</section>
<footer *ngIf="!id">
  <div class="right">
      <button kendoButton class="mr-24" themeColor="primary" fillMode="link" (click)="clearPage()">Clear Page</button>
      <button kendoButton class="mr-16" themeColor="primary" fillMode="solid" (click)="save(true)"
      [disabled]="validateForm">Create Investigation</button>
  </div>
</footer>
<app-reject-reactivate-modal
  *ngIf="investigationRejectReactivateModal"
  [opened]="investigationRejectReactivateModal"
  [mode]="investigationRejectReactivateModalMode"
  [id]="id"
  [headerIcon]="'assets/svg/warning.svg'"
  (closeModel)="investigationRejectReactivateModalResponse($event)"
></app-reject-reactivate-modal>
