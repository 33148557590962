import { Component, Directive, EventEmitter, Input, Output, Optional, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ISelectStaffModalData, SelectStaffComponent } from 'src/app/shared/components/select-staff/select-staff.component';
import { IApiUser, IApiAddAddressInput, IApiAddress, IApiAddVehicleInput, IApiAddEmailInput, IApiEmail, IApiInvestigationRole, IApiPhone, IApiAddPhoneInput, IApiUpdateUserDetailInput, IApiUpdateUserProfileInput, IApiVehicle, IApiCertificationType, IApiCertificationFilterType, IApiCertificationTypeFilterType, IApiInvestigationRoleNames, IApiCertificationTypeOrderBy, IApiVehicleFilterType, IApiAddUserInput, IApiPermissionRole, IApiUserDetailStatus, IApiPermissionRoleOrderBy, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { StateService, UserService, InvestigationRoleService, AddressTypeService, PermissionRoleService, AuthService } from 'src/app/shared/services';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { VehicleService } from 'src/app/shared/services/vehicle';
import { forkJoin, Observable, concat } from 'rxjs';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { removeTypename, unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { UserDetailService } from 'src/app/shared/services/user';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CertificationTypeService } from 'src/app/shared/services/certification/certification-type/certification-type.service';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { GoogleMapService } from 'src/app/shared/modules/google-maps/google-map.service';
import { NgForm } from '@angular/forms';
import { staffWorkingHoursOwnUpdate, staffUserInformationOwnUpdate, staffEmergencyInformationOwnUpdate, staffContactInformationOwnUpdate, staffContactInformationUpdate, staffUserInformationUpdate, staffEmergencyInformationUpdate, staffWorkingHoursUpdate } from "src/app/shared/helpers/auth-config/staff.config";
import { timezones } from 'src/app/shared/helpers/helper';

@Component({
  selector: 'app-create-update-staff',
  templateUrl: './create-update-staff.component.html',
  styleUrls: ['./create-update-staff.component.scss']
})
export class CreateUpdateStaffComponent {

  public authConfig = {
    staffContactInformationUpdate,
    staffUserInformationUpdate,
    staffEmergencyInformationUpdate,
    staffWorkingHoursUpdate,
    staffContactInformationOwnUpdate,
    staffEmergencyInformationOwnUpdate,
    staffUserInformationOwnUpdate,
    staffWorkingHoursOwnUpdate
  };


  @Output() staffName = new EventEmitter<string>();
  @ViewChild("userForm") userForm: NgForm;
  @ViewChild("staffEmergencyInformationUpdate") staffEmergencyInformationUpdatePer;
  @ViewChild("staffEmergencyInformationOwnUpdate") staffEmergencyInformationOwnUpdatePer;
  @ViewChild("staffWorkingHoursUpdate") staffWorkingHoursUpdatePer;
  @ViewChild("staffWorkingHoursOwnUpdate") staffWorkingHoursOwnUpdatePer;
  @ViewChild("staffUserInformationUpdate") staffUserInformationUpdatePer;
  @ViewChild("staffUserInformationOwnUpdate") staffUserInformationOwnUpdatePer;
  @ViewChild("staffContactInformationUpdate") staffContactInformationUpdatePer;
  @ViewChild("staffContactInformationOwnUpdate") staffContactInformationOwnUpdatePer;

  public userDetailStatus: typeof IApiUserDetailStatus = IApiUserDetailStatus;
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public user: IApiUser = {
    id: null,
    firstName: "",
    lastName: "",
    middleName: "",
    nickname: "",
    suffix: "",
    title: "",
    email: null,
    UserDetail: {
      id: null,
      requiredHoursQuarterly: 0,
      additionalInformation: "",
      hasNefcoVehicle: false,
      hasNefcoCreditCard: false,
      vehicleRegistrationState: "",
      vehicleInsuranceCompany: "",
      emergencyContactName: "",
      emergencyContactInfo: "",
      payRateHourly: 0,
      status: this.userDetailStatus.Active,
      fullTime: true,
      driversLicenseNumber: "",
      driversLicenseState: "",
      startDate: "",
      endDate: ""
    },
    Positions: [
      {
        id: null,
        title: ""
      }
    ],
    Addresses: [
      {
        id: null,
        state: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        postal: "",
        isPrimary: false,
        Type: {
          id: "",
          name: ""
        },
        latitude: null,
        longitude: null
      }
    ],
    Emails: [{
      id: null,
      TypeId: null,
      address: "",
      isPrimary: false,
      Type: {
        id: null,
        name: ""
      }
    }],
    Phones: [
      {
        id: null,
        TypeId: null,
        number: "",
        isPrimary: true,
        Type: {
          id: null,
          name: ""
        }
      }
    ],
    Certifications: [{
      id: null,
      title: ""
    }],
    Vehicles: [],
    InvestigationRoles: []
  };

  // Password for Auth0
  public password: string;
  public verifyPassword: string;

  // Permission Roles
  public permRoles: IApiPermissionRole[];
  public selectedPermRole: string;
  public PermissionIds = [];

  // Addresses
  // public updatedAddresses: IApiAddAddressInput[] = [];

  // Loading Google Maps API
  public mapsLoaded = false;

  // Positions / Investigation Roles
  public roles: IApiInvestigationRole[];
  public selectedRoles: string[];

  public assignedStaff: IApiUser[] = [];
  public regionalManager: IApiUser;
  public regionalManagerId = null;
  public assignedRegionalStaff: IApiUser[] = [];

  // Restrictions
  public restrictions: IApiCertificationType[];
  public selectedRestrictions: [];
  public authenticatedUserId = null;
  public timeZones = timezones;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    public statesService: StateService,
    private loader: LoaderService,
    private userDetailService: UserDetailService,
    private notifications: NotificationsService,
    private _investigationRoleService: InvestigationRoleService,
    private _permissionRoleService: PermissionRoleService,
    private _certificationTypeService: CertificationTypeService,
    public dialogRef: MatDialogRef<CreateUpdateStaffComponent>,
    private _mapService: GoogleMapService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) {
    // Init Google Maps
    this.initGoogleMaps();
    this.authService.authenticatedUser.subscribe((u) => this.authenticatedUserId = u.id);
    // User Info
    if (this.data.userId) {
      this.loader.show$(forkJoin([
        // Get Restrictions
        this._certificationTypeService.get([{
          type: IApiCertificationTypeFilterType.Restriction,
          value: 'true',
        }], {
          // Need SortOrder to return all of the certifications. Without it, only returns the first 25
          sortOrder: SortOrder.ASC,
          orderBy: IApiCertificationTypeOrderBy.Title
        }).pipe(
          unwrapConnection(),
          tap((restrictions) => this.restrictions = restrictions)
        ),
        // Get Investigation Roles / Positions
        this._investigationRoleService.investigationRoles.pipe(
          tap((roles) => this.roles = roles)
        ),
        // Get User Data
        this.getProfileData(this.data.userId),
        this._permissionRoleService.get([], { orderBy: IApiPermissionRoleOrderBy.Name, sortOrder: SortOrder.ASC }).pipe(
          unwrapConnection(),
          tap((roles) => this.permRoles = roles)
        )
      ])
      ).subscribe();
    }

    // Will we need this? Only if we allow a user to be created here, TBD
    else {
      // Unsure why, loader continues to spin if try to use it here
      forkJoin([
        this._investigationRoleService.investigationRoles.pipe(
          tap((roles) => this.roles = roles)
        ),
        this._permissionRoleService.get([], { orderBy: IApiPermissionRoleOrderBy.Name, sortOrder: SortOrder.ASC }).pipe(
          unwrapConnection(),
          tap((roles) => this.permRoles = roles)
        ),
        this._certificationTypeService.get([{
          type: IApiCertificationTypeFilterType.Restriction,
          value: 'true',
        }], {
          // Need SortOrder to return all of the certifications. Without it, only returns the first 25
          sortOrder: SortOrder.ASC,
          orderBy: IApiCertificationTypeOrderBy.Title
        }).pipe(
          unwrapConnection(),
          tap((restrictions) => this.restrictions = restrictions)
        )
      ]).subscribe();
    }
  }

  public isPrimaryToggle(isPrimary: boolean, index: number) {
    if (isPrimary) {
      this.user.Phones.forEach(obj => obj.isPrimary = false);
      this.user.Phones[index].isPrimary = true;
    }
  }

  private initGoogleMaps() {
    this._mapService.loadGoogleMapApi().subscribe(() => {
      this.mapsLoaded = true;
    });
  }

  public assignStaff() {
    const data: ISelectStaffModalData = {
      user: {...this.user, AssignedStaff: this.assignedStaff},
      staffType: 'TECH_REVIEWER',
      excludeUserId: this.data?.userId
    };
    return this.dialog.open(SelectStaffComponent, {
      width: "80%",
      height: "90%",
      data
    }).afterClosed().pipe(
      filter((v) => !!v)
    ).subscribe((assigned: IApiUser[]) => {
      this.assignedStaff = assigned;
    });
  }

  public investigatorFilter(position) {
    return position.title === IApiInvestigationRoleNames.TechReviewer;
  }

  /* Regional manager assignment */
  public assignStaffToRegionalManager() {
    const data: ISelectStaffModalData = {
      user: {...this.user, AssignedRegionalStaff: this.assignedRegionalStaff},
      staffType: 'REGIONAL_MANAGER',
      excludeUserId: this.data?.userId
    };
    return this.dialog.open(SelectStaffComponent, {
      width: "80%",
      height: "90%",
      data
    }).afterClosed().pipe(
      filter((v) => !!v)
    ).subscribe((assigned: IApiUser[]) => {
      this.assignedRegionalStaff = assigned;
    });
  }

  public regionalManagerFilter(position) {
    return position.title === IApiInvestigationRoleNames.RegionalManager;
  }

  public regionalOrInvestigatorFilter(position) {
    return position.title === IApiInvestigationRoleNames.TechReviewer || position.title === IApiInvestigationRoleNames.Investigator;
  }
  /* Regional manager assignment */


  public selectedRegionalManager(userId) {
    this.regionalManagerId = userId;
  }

  /* TechReviewer OR Investigator */
  public techReviewerAndInvestigatorFilter(position) {
    return position.title === IApiInvestigationRoleNames.Investigator || position.title === IApiInvestigationRoleNames.TechReviewer;
  }

  // TODO - Implement this better & ensure password is strong enough
  public get passwordMatch() {
    return this.password === this.verifyPassword;
  }

  public async onUserSubmit() {
    this.loader.show();
    let message = 'Profile Updated!';

    const {
      id,
      msGraphId,
      twilioPhoneNumber,
      email,
      firstName,
      lastName,
      middleName,
      nickname,
      title,
      suffix,
      Emails,
      Phones,
      Addresses,
      Vehicles,
      UserDetail,
      timezone,
    } = this.user;

    const userDetail: IApiUpdateUserDetailInput = UserDetail;
    const userData: IApiUpdateUserProfileInput = {
      id,
      msGraphId,
      twilioPhoneNumber: twilioPhoneNumber,
      email,
      firstName,
      lastName,
      middleName,
      nickname,
      title,
      suffix,
      PermissionIds: this.PermissionIds,
      // Positions: this.selectedRoles ? this.selectedRoles.map((p) => ({ InvestigationRoleId: p, UserId: id })) : [],
      Restrictions: this.selectedRestrictions ? this.selectedRestrictions.map((p) => ({ CertificationTypeId: p, TypeId: p, UserId: id, status: "", issuedAt: Date.now(), expiresAt: new Date(new Date().setFullYear(new Date().getFullYear() + 100)) })) : [],
      Emails: Emails ? this.removeEmptyObj(Emails, 'address').map((v: IApiEmail) => ({ TypeId: v.Type.id, isPrimary: v.isPrimary, address: v.address })) : [],
      Phones: Phones ? this.removeEmptyObj(Phones, 'number').map((v: IApiPhone) => ({ TypeId: v.Type.id, isPrimary: v.isPrimary, number: v.number, extension: v.extension })) : [],
      Addresses: Addresses ? Addresses.map((v) => {
        const typeId = v.TypeId ? v.TypeId : v.Type.id;
        delete v.id;
        this.removeExtras(v);
        return { ...v, TypeId: typeId } as IApiAddAddressInput;
      }) : [],
      Vehicles: Vehicles ? Vehicles.map((v: IApiVehicle) => ({ UserId: id, make: v.make, model: v.model, year: v.year, vinNumber: v.vinNumber })) : [],
      AssignToUsers: this.assignedStaff.map((e) => e.id),
      AssignToRegionalUsers: this.assignedRegionalStaff.map((e) => e.id),
      RegionalManagerId: this.regionalManagerId,
      timezone,
    };

    // Create a new user
    if (!userData.id) {
      const defaultTwilioPhone = Phones.find(({ Type }) => Type.name === "Cell")?.number;
      if (!twilioPhoneNumber && !defaultTwilioPhone) {
        this.notifications.notify("No Twilio Phone number or Cell Phone number found! Please add and try again.");
        this.loader.hide();
        return;
      }
      const smsPhone = twilioPhoneNumber ? ("+1").concat(twilioPhoneNumber) : ("+1").concat(defaultTwilioPhone);
      const newUser: IApiAddUserInput =
      {
        password: this.password,
        msGraphId,
        // permissionId: this.selectedPermRole,
        twilioPhoneNumber: smsPhone,
        firstName,
        lastName,
        email,
        PermissionIds: this.PermissionIds
        // InvestigationRoles: this.selectedRoles
      };
      try {
        await this.userService.addUser(newUser).pipe(this.notifications.catchAlertPipe()).toPromise().then((user) => {
          userData.id = user.id;
          // userData.Positions = userData.Positions.map((p) => ({ ...p, UserId: user.id }));
          userData.Restrictions = userData.Restrictions.map((r) => ({...r, UserId: user.id}));
          message = 'Profile Created!';
        });
      } catch (error) {
        this.loader.hide();
      }
    }
    /* Email already exists should not update profile */
    if (!userData?.id) {
      return;
    }
    // If userdetail does not yet exist for this user, create it.
    if (!userDetail.id) {
      delete userDetail.id;
      userDetail.UserId = userData.id;
      if (!userDetail.endDate) {
        delete userDetail.endDate;
      }
      await this.userDetailService.addUserDetail(userDetail).pipe(this.notifications.catchAlertPipe()).toPromise();
    } else {
      userData.UserDetail = userDetail;
    }

    // Remove field based on permission
    if (!(this.staffEmergencyInformationUpdatePer?.enabled || (this.staffEmergencyInformationUpdatePer?.enabled && this.isOwnerUser))) {
      delete userData.UserDetail.emergencyContactInfo;
      delete userData.UserDetail.emergencyContactName;
    }

    if (!(this.staffWorkingHoursUpdatePer?.enabled || (this.staffWorkingHoursOwnUpdatePer?.enabled && this.isOwnerUser))) {
      delete userData.UserDetail.requiredHoursQuarterly;
    }

    if (!(this.staffUserInformationUpdatePer?.enabled || (this.staffUserInformationOwnUpdatePer?.enabled && this.isOwnerUser))) {
      delete userData.Vehicles;
      delete userData.UserDetail.driversLicenseNumber;
      delete userData.UserDetail.driversLicenseState;
      delete userData.UserDetail.vehicleInsuranceCompany;
      delete userData.UserDetail.vehicleRegistrationState;
      delete userData.UserDetail.status;
      delete userData.UserDetail.fullTime;
      delete userData.UserDetail.hasNefcoCreditCard;
      delete userData.UserDetail.hasNefcoVehicle;
      delete userData.Restrictions;
      delete userData.UserDetail.startDate;
      delete userData.UserDetail.endDate;
      delete userData.UserDetail.payRateHourly;
      delete userData.RegionalManagerId;
    }

    if (!(this.staffContactInformationUpdatePer?.enabled || (this.staffContactInformationOwnUpdatePer?.enabled && this.isOwnerUser))) {
      delete userData.title;
      delete userData.Addresses;
      delete userData.AssignToUsers;
      delete userData.Emails;
      delete userData.Phones;
      delete userData.Positions;
      delete userData.email;
      delete userData.firstName;
      delete userData.lastName;
      delete userData.suffix;
      delete userData.middleName;
      delete userData.msGraphId;
      delete userData.nickname;
      delete userData.twilioPhoneNumber;
      delete userData.UserDetail.additionalInformation;
      delete userData.timezone;
    }

    // Update the profile
    this.userService.updateUserProfile(userData).pipe(
      this.notifications.snackbarPipe(message),
      this.notifications.catchAlertPipe(),
      tap(() => this.loader.hide())
    ).subscribe(() => this.dialogRef.close());
  }

  private getProfileData(id: string) {
    return this.loader.show$(
      forkJoin([
        this.userService.getByIdWithoutIntestigationRole(id)
      ])).pipe(
        removeTypename(),
        tap(([user]) => {
          if (!user.UserDetail) user.UserDetail = {};
          delete user.UserDetail.remainingHoursQuarterly;
          this.user = user;
          this.assignedStaff = user?.AssignedStaff;
          this.regionalManager = user?.RegionalManager,
          this.regionalManagerId = user?.RegionalManagerId,
          this.assignedRegionalStaff = user?.AssignedRegionalStaff;
          this.selectedRoles = user.Positions.map(roles => roles.id);
          this.PermissionIds = user?.PermissionRoles?.map(perms => perms?.id)
          this.selectedRestrictions = user.Restrictions.map(restrictions => restrictions.CertificationTypeId);
          user.Addresses.length ? this.user.Addresses.forEach(address => address.TypeId = address.Type.id) : this.user.Addresses = [];
          this.user.timezone = this.user.timezone || 'America/New_York';
          this.staffName.emit(`${user.firstName} ${user.lastName}`);
        })
      );
  }

  public selectedTitle(id, array) {
    const title = !array ? "" : array.find((x) => x.id === id).title;
    return title;
  }

  // Address
  public setAddress(event: IApiAddress, index) {
    delete event.id;
    this.user.Addresses[index] = event;
    this.cdr.detectChanges();
  }

  // Adding Phones, Emails, Addresses
  public addPhone() {
    this.user.Phones.push({
      id: null,
      isPrimary: !this.user.Phones.length,
      number: null,
      TypeId: null,
      Type: {
        id: null,
      }
    });
  }

  public addEmail() {
    this.user.Emails.push({
      id: null,
      address: null,
      isPrimary: false,
      TypeId: null,
      Type: {
        id: null
      }
    });
  }

  public addAddress() {
    this.user.Addresses.push({
      id: null,
      address1: null,
      country: "United States",
      Type: {
        id: null
      }
    });
  }

  public addVehicle() {
    this.user.Vehicles.push({
      id: null,
      make: "",
      model: "",
      year: null,
      vinNumber: ""
    });
  }

  // Removing Phones, Emails, Addresses, Vehicle
  public removePhone(index: number) {
    this.user.Phones.splice(index, 1);
  }
  public removeEmail(index: number) {
    this.user.Emails.splice(index, 1);
  }
  public removeAddress(index: number) {
    this.user.Addresses.splice(index, 1);
  }
  public removeVehicle(index: number) {
    this.user.Vehicles.splice(index, 1);
  }

  private removeExtras(obj: any) {
    // no error thrown by delete if the key isn't there so lump all of these together into one function we can use
    delete obj.__typename;
    delete obj.Role;
    delete obj.Type;
    delete obj.updatedAt;
    delete obj.createdAt;
    delete obj.addressableTypeId;
    delete obj.addressableType;
    delete obj.Branches;
  }

  // Removes empty objects from email and phone when saving a user
  public removeEmptyObj(array, key) {
    return array.filter((x) => x[key] !== null && x[key] !== "null" && x[key] !== "");
  }

  public get hasSMSNumber() {
    return (this.user.Phones.find(({ Type }) => (Type.name === "Cell")) || this.user.twilioPhoneNumber) ? true : false;
  }

  public validForm() {
    if (this.user?.id) {
      if (this.staffContactInformationUpdatePer?.enabled || (this.staffContactInformationOwnUpdatePer?.enabled && this.isOwnerUser)) {
        return this.userForm?.form?.valid && this.hasSMSNumber && this.passwordMatch && ((this.user.Addresses?.length > 0 &&
          this.user.Addresses?.every(
            (item) => (item?.address1 || '').trim() && (item?.city || '').trim() && (item?.state || '').trim() && (item?.postal || '').trim()
          )) ||
          this.user.Addresses?.length <= 0);
      } else {
        return this.userForm?.form?.valid;
      }
    } else {
      return (
        this.userForm?.form?.valid &&
        this.hasSMSNumber &&
        this.passwordMatch &&
        ((this.user.Addresses?.length > 0 &&
          this.user.Addresses?.every(
            (item) => (item?.address1 || '').trim() && (item?.city || '').trim() && (item?.state || '').trim() && (item?.postal || '').trim()
          )) ||
          this.user.Addresses?.length <= 0)
      );
    }
  }

  public trackByIndex(index: number) {
    return index;
  }

  public get isOwnerUser(): boolean {
    return this.data.userId === this.authenticatedUserId;
  }
}
