import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { groupBy } from '@progress/kendo-data-query';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { ReactiveFormHelper, getRiskTypeIcon } from 'src/app/shared/helpers/reactive-form.class';
import { IState } from 'src/app/shared/interfaces/state.interfaces';
import { IApiRiskType } from 'src/app/shared/modules/graphql/types/types';
import { PortalService, StateService } from 'src/app/shared/services';
import moment from 'moment';

@Component({
  selector: 'app-loss-information',
  templateUrl: './loss-information.component.html',
  styleUrls: ['./loss-information.component.scss']
})
export class LossInformationComponent implements OnInit {

  @Input() formGroupName: string;
  lossInformation: FormGroup;
  public stateList: Array<IState>;
  public stateFiltered: Array<IState>;
  public groupedDataStates: any;
  public today = new Date();
  public nefcoTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#020e26',
      buttonColor: '#90bbfe'
    },
    dial: {
      dialBackgroundColor: '#020e26',
    },
    clockFace: {
      clockFaceBackgroundColor: '#020e26',
      clockHandColor: '#969696',
      clockFaceTimeInactiveColor: '#90bbfe'
    }
  };
  public yearList: Array<{text: string; value: string}>;
  public yearListFiltered: Array<{text: string; value: string}>;
  public selectedLossType: string;
  public riskTypeList: IApiRiskType[];
  public customTimeFocus = false;
  constructor(
    private rootFormGroup: FormGroupDirective,
    private stateService: StateService,
    private portalService: PortalService
    ) {
  }

  ngOnInit(): void {
    this.lossInformation = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
     /* Atleast One Required claimNumber OR policyNumber */
    this.lossInformation.addValidators([
      ReactiveFormHelper.atLeastOneHasValue("claimNumber", "policyNumber"),
      ReactiveFormHelper.checkMaxTimeWithDate(
        "lossDate",
        "lossTime"
      ),
    ]);
    this.stateList = this.stateService.allStates;
    this.stateFiltered = this.stateService.allStates;
    this.groupedDataStates = groupBy(this.stateFiltered, [
      { field: "type" },
    ]);

    this.yearList = NefcoDateHelper.getYearList();
    this.yearListFiltered = NefcoDateHelper.getYearList();
    this.portalService.getRiskType().subscribe(res => this.riskTypeList = res);
  }

  public disabledDates = (date: Date): boolean => {
    return moment(date).isAfter();
  };

  public handleFilter(value) {
    this.stateFiltered = this.stateList.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    this.groupedDataStates = groupBy(this.stateFiltered, [
      { field: "type" },
    ]);
  }

  public handleYearFilter(value) {
    this.yearListFiltered = this.yearList.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public setLossTpe(id: string, name: string) {
    this.lossInformation.patchValue({RiskTypeId: id});
    this.selectedLossType = name;
    if (this.selectedLossType === 'Auto' || this.selectedLossType === 'Boat') {
      this.lossInformation.get('sameLocation').setValidators([Validators.required]);
      this.lossInformation.patchValue({sameLocation: null});
    } else {
      this.lossInformation.patchValue({sameLocation: null});
      this.lossInformation.get('sameLocation').clearValidators();
    }
    this.lossInformation.get('sameLocation').updateValueAndValidity();
    this.lossInformation.get('RiskTypeId').updateValueAndValidity();
  }

  public getIcon(RiskTypeId: string) {
    return getRiskTypeIcon(RiskTypeId);
  }

  public changedSameLocationVehicle(type: boolean) {
    if (type) {
      this.lossInformation.patchValue({lossVehicleAddress: ''});
      this.lossInformation.patchValue({lossVehicleCity: ''});
      this.lossInformation.patchValue({lossVehicleState: ''});
      this.lossInformation.patchValue({lossVehicleZipcode: ''});
      this.lossInformation.get('lossVehicleAddress').clearValidators();
      this.lossInformation.get('lossVehicleCity').clearValidators();
      this.lossInformation.get('lossVehicleState').clearValidators();
      this.lossInformation.get('lossVehicleZipcode').clearValidators();
    } else {
      this.lossInformation.get('lossVehicleAddress').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.lossInformation.get('lossVehicleCity').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.lossInformation.get('lossVehicleState').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
      this.lossInformation.get('lossVehicleZipcode').setValidators([Validators.required, ReactiveFormHelper.noWhitespaceValidator]);
    }
    this.lossInformation.get('lossVehicleAddress').updateValueAndValidity();
    this.lossInformation.get('lossVehicleCity').updateValueAndValidity();
    this.lossInformation.get('lossVehicleState').updateValueAndValidity();
    this.lossInformation.get('lossVehicleZipcode').updateValueAndValidity();
  }

}
