<form [formGroup]="addressForm">
  <div class="row">
    <div class="col-12">
      <mat-checkbox (change)="manualAddress($event)" [(ngModel)]="isCustomAddress" [checked]="isCustomAddress" [ngModelOptions]="{standalone: true}">
        Custom Address
      </mat-checkbox>
      <p class="font-style-italic">Only use "custom address" if the search does not return the correct address and you would like to manually enter an address.</p>
    </div>
    <div class="col-12">
      <mat-form-field>
        <mat-label> Address Search </mat-label>
        <input matInput type="text" formControlName="addressSearch" #addresstext />
        <button mat-icon-button disableRipple matSuffix>
          <mat-icon (click)="clearAddress()">clear</mat-icon>
        </button>
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="col-12" *ngIf="!!showType">
      <mat-form-field>
        <mat-label> Type </mat-label>
        <mat-select formControlName="TypeId">
          <mat-option *ngFor="let type of addressTypes" [value]="type.id" [innerHTML]="type.name"></mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field>
        <mat-label> Address 1 </mat-label>
        <input matInput type="text" formControlName="address1" />
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field>
        <mat-label> Address 2 </mat-label>
        <input matInput type="text" formControlName="address2" />
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field>
        <mat-label> City </mat-label>
        <input matInput type="text" formControlName="city" />
      </mat-form-field>
    </div>
    <div class="col-2">
      <mat-form-field>
        <mat-label>State</mat-label>
        <mat-select formControlName="state">
          <mat-option *ngFor="let state of stateService?.allStates" [value]="state.value" [innerHTML]="state.text">
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field>
        <mat-label> Zip Code </mat-label>
        <input matInput type="text" (change)="zipChanged($event)" formControlName="postal" />
      </mat-form-field>
    </div>
  </div>
</form>
