<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationInformationListView" #investigationInformationListView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationInformationUpdate" #investigationInformationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationBillingInformationView" #investigationBillingInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationBillingInformationUpdate" #investigationBillingInformationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationLinkedInvestigationView" #investigationLinkedInvestigationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationLinkedInvestigationUpdate" #investigationLinkedInvestigationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationVehiclesListView" #investigationVehiclesListView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationVehiclesUpdate" #investigationVehiclesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationVehiclesCreate" #investigationVehiclesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationReject" #investigationReject="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationReceivedDateUpdate" #investigationReceivedDateUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationScheduledDateUpdateAll" #investigationScheduledDateUpdateAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationScheduledDateUpdateOwn" #investigationScheduledDateUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationScheduledDateView" #investigationScheduledDateView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationPartiesListView" #investigationPartiesListView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationPartiesUpdate" #investigationPartiesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationAssignStaffListView" #investigationAssignStaffListView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationAssignStaffCreate" #investigationAssignStaffCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationAssignStaffUpdate" #investigationAssignStaffUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsProfileContactInformationViewAssigned" #contactsProfileContactInformationViewAssigned="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsProfileCompanyInformationView" #contactsProfileCompanyInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsInvestigations" #contactsInvestigations="appEnforcePermission"></div>
<!-- Permissions -->
<section class="mat-typography">
  <div
    class="d-flex flex-row-reverse"
    *ngIf="editActions?.length"
  >
    <kendo-dropdownbutton
        [data]="editActions"
        themeColor="light"
        fillMode="solid"
        buttonClass="grid-action-items-reverse"
        [iconClass]="isOpenMoreActions ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-up'"
        (open)="isOpenMoreActions = true"
        (close)="isOpenMoreActions = false"
        (itemClick)="editDetails($event?.text)"
        [popupSettings]="{
            appendTo: 'component',
            animate: false,
            width: 250,
            popupClass: 'grid-action-items-menu mt-12'
        }"
        >
        More Actions
        <ng-template kendoDropDownButtonItemTemplate let-dataItem>
          <span [ngClass]="dataItem.text === 'Reject Investigation' ? 'red-item' : 'gray-item'">{{ dataItem.text }}</span>
        </ng-template>
    </kendo-dropdownbutton>
  </div>
  <div
    [appEnforcePermission]="authConfig.companiesViewAll"
    #companiesViewAll="appEnforcePermission"
  ></div>
  <div class="toolbar">
    <div>
      Priority:
      <p class="textbold" [innerHTML]="investigation.Priority?.name"></p>
    </div>
    <div>
      Status:
      <p class="textbold" [innerHTML]="investigation.status"></p>
    </div>

    <kendo-formfield>
      <div class="k-checkbox-wrap">
        <input
        #isIncendiary
        kendoCheckBox type="checkbox"
        [(ngModel)]="investigation.isIncendiary"
        (change)="markIncendiary($event.target)"
        [disabled]="!investigationInformationUpdate?.enabled"
        />
        <kendo-label
        [for]="isIncendiary"
        class="k-checkbox-label font-weight-600 checkbox-margin"
        text="Incendiary"
        ></kendo-label>
      </div>
    </kendo-formfield>

    <kendo-formfield>
      <div class="k-checkbox-wrap">
        <input
        #onHold
        kendoCheckBox type="checkbox"
        (change)="markOnHold($event.target)"
        [(ngModel)]="investigation.onHold"
        [disabled]="!investigationInformationUpdate?.enabled"
        />
        <kendo-label
        [for]="onHold"
        class="k-checkbox-label font-weight-600 checkbox-margin"
        text="On hold"
        ></kendo-label>
      </div>
    </kendo-formfield>

    <kendo-formfield>
      <div class="k-checkbox-wrap">
        <input
        #isOngoing
        kendoCheckBox type="checkbox"
        (change)="markOngoing($event.target)"
        [(ngModel)]="investigation.isOngoing"
        [disabled]="!investigationInformationUpdate?.enabled"
        />
        <kendo-label
        [for]="isOngoing"
        class="k-checkbox-label font-weight-600 checkbox-margin"
        text="Ongoing"
        ></kendo-label>
      </div>
    </kendo-formfield>

    <kendo-formfield>
      <div class="k-checkbox-wrap">
        <input
        #isRetainerReceived
        kendoCheckBox type="checkbox"
        (change)="markRetainerReceived($event.target)"
        [(ngModel)]="investigation.isRetainerReceived"
        [disabled]="!investigationInformationUpdate?.enabled"
        />
        <kendo-label
        [for]="isRetainerReceived"
        class="k-checkbox-label font-weight-600 checkbox-margin"
        text="Retainer Received"
        ></kendo-label>
      </div>
    </kendo-formfield>

    <p class="kendo-caption">
      <ng-container *ngIf="investigationScheduledDateView?.enabled">
        <span class="scheduled-edit-icon"
          *ngIf="getLatestInvestigationScheduledEntry?.scheduledDate && (investigation?.InvestigationStaff?.length && (investigationScheduledDateUpdateAll?.enabled || investigationScheduledDateUpdateOwn?.enabled))">
          <i class="fa fa-pencil" (click)="nextSchedule('NEXT_SCHEDULE')"></i>
        </span>
        <span class="received">
          <span *ngIf="investigation?.InvestigationStaff?.length && getLatestInvestigationScheduledEntry?.scheduledDate">Next
            Scheduled:
            {{ (getLatestInvestigationScheduledEntry?.scheduledDate | dateWithTimezone : "MM-DD-YYYY, hh:mm a" : investigation?.timezone) }}</span>
        </span>
        <span class="cursor" *ngIf="investigation?.InvestigationStaff?.length && !getLatestInvestigationScheduledEntry?.scheduledDate" (click)="nextSchedule('NEW_SCHEDULE')">
          Scheduled:
          <kendo-badge class="fw600 ml-8" position="none" size="medium" rounded="full" themeColor="error">
            No scheduled date available </kendo-badge>
        </span>
      </ng-container>
      <span *ngIf="investigation?.InvestigationStaff?.length && investigationScheduledDateView?.enabled">&nbsp; |&nbsp;
      </span>
      <span class="received">
        <span class="scheduled-edit-icon" (click)="openRecivedDateModal()"
          *ngIf="investigationReceivedDateUpdate?.enabled && investigationInformationUpdate?.enabled"><i
            class="fa fa-pencil"></i></span>
        Received:
        {{ investigation.receivedDate | customDate : "MM-dd-yyyy, hh:mm a" }} |
        {{
        investigation.InvestigationSource?.name !== "Manual Entry"
        ? "Via Online Form"
        : "By: " + (investigation.CreatedBy | fullName)
        }}
      </span>
      <br />
      <span class="canceled-rejected" *ngIf="investigation.deletedAt">
        Cancelled:
        {{ investigation.deletedAt | customDate : "MM-dd-yyyy hh:mm:ss a" }}
      </span>
    </p>
  </div>

  <!-- Investigation / Client -->
  <div class="contentWrapper" *ngIf="investigationInformationListView?.enabled">
    <div class="h-action">
      <h2 class="mb-0">Investigation Information</h2>
      <i class="fa fa-pen" *ngIf="investigationInformationUpdate?.enabled"
            (click)="editDetails('editInfo')"></i>
    </div>
    <div
      class="kendo-alert-wrapper"
      *ngIf="investigation?.Client?.isActive === false"
    >
      <app-kendo-alert
        [message]="
          'This client is missing from the system. To add their information, please click on the edit icon above.'
        "
        [routeLink]="'javascript:void(0)'"
        [routerLinkLabel]="'Review Client Details'"
        (linkClicked)="reviewClient()"
      ></app-kendo-alert>
    </div>
    <div class="flexContainer">
      <div class="equal">
        <kendo-card class="p24" width="100%"
          *ngIf="investigation?.Client?.isActive === true"
        >
          <kendo-card-header>
            <h3 class="kendo-h3">Client</h3>
          </kendo-card-header>
          <kendo-card-body>
            <div class="column">
              <!-- contact -->
              <div class="equal">
                <p class="kendo-caption">Contact</p>
                <p>
                  <a
                    class="linkmd"
                    [routerLink]="['/contacts/', investigation.Client.id]"
                    target="_blank"
                    *ngIf="contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled"
                  >
                    <u [innerHTML]="investigation.Client | fullName"></u>
                  </a>
                  <ng-container *ngIf="!(contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled)">
                    <ng-container
                      *ngTemplateOutlet="
                        contactName;
                        context: { name: (investigation.Client | fullName) }
                      "
                    ></ng-container>
                  </ng-container>
                </p>
              </div>

              <!-- role -->
              <div class="equal">
                <p class="kendo-caption">Role</p>
                <p class="kendo-caption-body" [innerHTML]="investigation.Client?.Role.name"></p>
              </div>

              <!-- phone -->
              <div class="equal">
                <p class="kendo-caption">Phone</p>
                <app-email-phone-view
                  *ngFor="let clientPhone of investigation.Client?.Phones"
                  [phone]="clientPhone"
                ></app-email-phone-view>
              </div>

              <!-- email -->
              <div class="equal">
                <p class="kendo-caption">Email</p>
                <app-email-phone-view
                  *ngFor="let clientEmail of investigation.Client?.Emails"
                  [email]="clientEmail"
                ></app-email-phone-view>
              </div>

              <!-- additional info -->
              <div class="equal">
                <p class="kendo-caption">Additional Information</p>
                <p class="kendo-caption-body">{{investigation.Client?.additionalInformation || '-'}}</p>
              </div>


              <!-- Client Requested Docusketch & isXactimate -->
              <div class="equal">
                <p class="kendo-caption">Xactimate</p>
                <p class="kendo-caption-body">{{investigation?.isXactimate ? 'Yes' : 'No'}}</p>
              </div>


            </div>
            <div class="column">
              <!-- company -->
              <div class="equal">
                <p class="kendo-caption">Company</p>
                <p class="kendo-caption-body" *ngIf="investigation?.ClientBranch; else ClientCompanyNA">
                  <a
                    class="linkmd"
                    [routerLink]="[
                      '/admin/companies/management/',
                      investigation?.ClientBranch?.Company?.id
                    ]"
                    target="_blank"
                    *ngIf="companiesViewAll.enabled; else companyName"
                  >
                    <u [innerHTML]="investigation?.ClientBranch?.Company?.name"></u>
                  </a>
                  <ng-template #companyName>
                    <p class="kendo-caption-body">{{ investigation?.ClientBranch?.Company?.name }}</p>
                  </ng-template>
                </p>
                <ng-template #ClientCompanyNA>
                  <p class="kendo-caption-body">N/A</p>
                </ng-template>
              </div>

               <!-- branch -->
               <div class="equal">
                <p class="kendo-caption">Branch</p>
                <p class="kendo-caption-body">
                  {{
                    investigation?.ClientBranch
                      ? investigation?.ClientBranch?.name
                      : "N/A"
                  }}
                </p>
              </div>

              <!-- address -->
              <div class="equal">
                <p class="kendo-caption">Address</p>
                <ng-container
                  *ngIf="investigation?.ClientBranch; else ClientAddress"
                >
                  <app-address-view
                    *ngFor="
                      let address of investigation?.ClientBranch?.Addresses
                    "
                    [address]="address"
                  ></app-address-view>
                </ng-container>
                <ng-template #ClientAddress>
                  <app-address-view
                    *ngFor="let address of investigation.Client?.Addresses"
                    [address]="address"
                  ></app-address-view>
                </ng-template>
              </div>

              <!-- Client Requested Docusketch -->
              <div class="equal">
                <p class="kendo-caption">Client Requested Docusketch</p>
                <p class="kendo-caption-body">{{investigation?.docusketchRequested ? 'Yes' : 'No'}}</p>
              </div>

              <!-- Client Requested Docusketch & isSymbility -->
              <div class="equal">
                <p class="kendo-caption">Symbility</p>
                <p class="kendo-caption-body">{{investigation?.isSymbility ? 'Yes' : 'No'}}</p>
              </div>
            </div>
          </kendo-card-body>
        </kendo-card>
        <kendo-card class="p24" width="100%"
          *ngIf="investigation?.Client?.isActive === false"
        >
          <kendo-card-header>
            <h3 class="kendo-h3">Client</h3>
          </kendo-card-header>
          <kendo-card-body>
            <div class="column">
              <!-- contact -->
              <div class="equal">
                <p class="kendo-caption">Contact</p>
                <p class="kendo-caption-body">-</p>
              </div>

              <!-- role -->
              <div class="equal">
                <p class="kendo-caption">Role</p>
                <p [innerHTML]="'-'"></p>
              </div>

              <!-- phone -->
              <div class="equal">
                <p class="kendo-caption">Phone</p>
                <p class="kendo-caption-body">-</p>
              </div>

              <!-- email -->
              <div class="equal">
                <p class="kendo-caption">Email</p>
                <p class="kendo-caption-body">-</p>
              </div>

              <!-- additional info -->
              <div class="equal">
                <p class="kendo-caption">Additional Information</p>
                <p [innerHTML]="'-'"></p>
              </div>
            </div>
            <div class="column">
               <!-- company -->
               <div class="equal">
                <p class="kendo-caption">Company</p>
                <p class="kendo-caption-body">-</p>
              </div>

              <!-- branch -->
              <div class="equal">
                <p class="kendo-caption">Branch</p>
                <p class="kendo-caption-body">-</p>
              </div>

               <!-- address -->
               <div class="equal">
                <p class="kendo-caption">Address</p>
                <p class="kendo-caption-body">-</p>
              </div>
            </div>
          </kendo-card-body>
        </kendo-card>
      </div>
      <div class="equal">
        <kendo-card class="p24" width="100%" *ngIf="investigation?.Client?.isActive === true">
          <!-- Bill To -->
          <kendo-card-header>
            <h3 class="kendo-h3">Bill To</h3>
          </kendo-card-header>
          <kendo-card-body>
            <div class="column">
              <!-- Contact -->
              <div class="equal">
                <p class="kendo-caption">Contact</p>
                <a
                  class="linkmd"
                  [routerLink]="['/contacts/', investigation.BillTo?.id]"
                  target="_blank"
                  *ngIf="contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled"
                >
                  <u [innerHTML]="investigation.BillTo | fullName"></u>
                </a>
                <ng-container *ngIf="!(contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled)">
                  <ng-container
                    *ngTemplateOutlet="
                      contactName;
                      context: { name: (investigation.BillTo | fullName) }
                    "
                  ></ng-container>
                </ng-container>
              </div>

              <!-- Role -->
              <div class="equal">
                <p class="kendo-caption">Role</p>
                <p class="kendo-caption-body" [innerHTML]="investigation.BillTo?.Role.name"></p>
              </div>

              <!-- Phone -->
              <div class="equal">
                <p class="kendo-caption">Phone</p>
                <app-email-phone-view
                  *ngFor="let billPhone of investigation.BillTo?.Phones"
                  [phone]="billPhone"
                ></app-email-phone-view>
              </div>

              <!-- email -->
              <div class="equal">
                <p class="kendo-caption">Email</p>
                <app-email-phone-view
                  *ngFor="let billToEmail of investigation.BillTo?.Emails"
                  [email]="billToEmail"
                ></app-email-phone-view>
              </div>
            </div>

            <div class="column">
              <!-- BillToBranch -->
              <div class="equal">
                <p class="kendo-caption">Company</p>
                <ng-container *ngIf="investigation?.BillToBranch; else BillToCompanyNA">
                  <a
                    class="linkmd"
                    [routerLink]="[
                      '/admin/companies/management/',
                      investigation?.BillToBranch.Company?.id
                    ]"
                    target="_blank"
                    *ngIf="companiesViewAll.enabled; else BillToCompanyName"
                  >
                    <u [innerHtml]="investigation?.BillToBranch?.Company?.name"></u>
                  </a>
                  <ng-template #BillToCompanyName>
                    <p class="kendo-caption-body">{{ investigation?.BillToBranch?.Company?.name }}</p>
                  </ng-template>
                </ng-container>
                <ng-template #BillToCompanyNA>
                  <p class="kendo-caption-body">N/A</p>
                </ng-template>
              </div>

               <!-- BillToBranch -->
              <div class="equal">
                <p class="kendo-caption">Branch</p>
                <p class="kendo-caption-body">
                  {{
                    investigation?.BillToBranch
                      ? investigation?.BillToBranch?.name
                      : "N/A"
                  }}
                </p>
              </div>

              <!-- Billing Address -->
              <div class="equal">
                <p class="mat-caption">Address</p>
                <ng-container
                  *ngIf="!investigation?.BillToBranch; else billToBranchInfo"
                >
                  <app-address-view
                    *ngIf="investigation?.BillTo?.BillingAddress"
                    [address]="investigation?.BillTo?.BillingAddress"
                  ></app-address-view>
                </ng-container>
                <ng-template #billToBranchInfo>
                  <app-address-view
                    *ngIf="investigation?.BillToBranch?.BillingAddress"
                    [address]="investigation?.BillToBranch?.BillingAddress"
                  ></app-address-view>
                </ng-template>
              </div>
            </div>
          </kendo-card-body>

          <kendo-card-header>
          <hr />
          </kendo-card-header>

          <!-- Billing Rules -->
          <kendo-card-header>
            <h3 class="kendo-h3">Billing Rules</h3>
          </kendo-card-header>

          <kendo-card-body>
            <div class="column">
              <!-- Company -->
              <div class="equal">
                <p class="kendo-caption">Company</p>
                <a
                  class="linkmd"
                  [routerLink]="[
                    '/admin/companies/management/',
                    investigation.Company?.id
                  ]"
                  target="_blank"
                  *ngIf="companiesViewAll.enabled; else companyNameText"
                >
                  <u [innerHTML]="investigation.Company?.name"></u>
                </a>
                <ng-template #companyNameText>
                  <p class="kendo-caption-body">{{ investigation.Company?.name }}</p>
                </ng-template>
              </div>
            </div>

            <div class="column">
              <!-- Address -->
              <div class="equal">
                <p class="kendo-caption">Address</p>
                <ng-container
                  *ngFor="let branch of investigation.Company?.Branches"
                >
                  <app-address-view
                    *ngIf="branch?.isPrimary"
                    [address]="getPrimary(branch?.Addresses)"
                  ></app-address-view>
                </ng-container>
              </div>
            </div>
          </kendo-card-body>
        </kendo-card>
        <kendo-card
          class="p24" width="100%"
          *ngIf="investigation?.Client?.isActive === false"
        >
          <kendo-card-header>
            <h3 class="kendo-h3">Bill To</h3>
          </kendo-card-header>

          <kendo-card-body>
            <div class="column">
              <!-- Contact -->
              <div class="equal">
                <p class="kendo-caption">Contact</p>
                <p>-</p>
              </div>

              <!-- Role -->
              <div class="equal">
                <p class="kendo-caption">Role</p>
                <p class="kendo-caption-body">-</p>
              </div>

              <!-- Phone -->
              <div class="equal">
                <p class="kendo-caption">Phone</p>
                <p class="kendo-caption-body">-</p>
              </div>

              <!-- Phone -->
              <div class="equal">
                <p class="kendo-caption">Email</p>
                <p class="kendo-caption-body">-</p>
              </div>
            </div>
            <div class="column">
              <!-- BillToBranch -->
              <div class="equal">
                <p class="kendo-caption">Company</p>
                <p class="kendo-caption-body">-</p>
              </div>

              <!-- BillToBranch -->
              <div class="equal">
                <p class="kendo-caption">Branch</p>
                <p class="kendo-caption-body">-</p>
              </div>

              <!-- Billing Address -->
              <div class="equal">
                <p class="kendo-caption">Address</p>
                <p class="kendo-caption-body">-</p>
              </div>
            </div>
          </kendo-card-body>
        </kendo-card>
      </div>
      <div class="equal">
        <kendo-card class="p24" width="100%">
          <kendo-card-header>
            <h3 class="kendo-h3">Loss Address</h3>
          </kendo-card-header>
          <kendo-card-body>
              <div class="column">
                <!-- Address -->
                <div class="equal">
                  <p class="kendo-caption">Address</p>
                  <app-address-view
                    [address]="investigation.LossAddress"
                  ></app-address-view>
                  <button kendoButton class="view-map" themeColor="primary" fillMode="link" (click)="openMap(investigation.LossAddress)">View Map</button>
                </div>
                <!-- Timezone -->
                <div class="equal">
                  <p class="kendo-caption">Timezone</p>
                  <p> {{ (investigation?.timezone | timezonePipe)|| '-' }} </p>
                </div>
              </div>

              <div class="column">
                <!-- Contact -->
                <div class="equal">
                  <p class="kendo-caption">Contact</p>
                  <p>
                    <a
                      class="linkmd"
                      [routerLink]="[
                        '/contacts/',
                        investigation.SceneContact?.id
                      ]"
                      target="_blank"
                      *ngIf="contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled"
                    >
                      <u [innerHTML]="investigation?.SceneContact | fullName" *ngIf="investigation?.SceneContact else noValue"></u>
                    </a>
                    <ng-container *ngIf="!(contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled)">
                      <ng-container
                        *ngTemplateOutlet="
                          contactName;
                          context: {
                            name: (investigation?.SceneContact | fullName)
                          }
                        "
                      ></ng-container>
                    </ng-container>
                  </p>
                </div>
                <!-- Phone -->
                <div class="equal">
                  <p class="kendo-caption">Phone</p>
                  <ng-container *ngIf="investigation.SceneContact?.Phones?.length else noValue">
                    <app-email-phone-view
                      *ngFor="
                        let scenePhone of investigation.SceneContact?.Phones
                      "
                      [phone]="scenePhone"
                    ></app-email-phone-view>
                  </ng-container>
                </div>
                <!-- Email -->
                <div class="equal">
                  <p class="kendo-caption">Email</p>
                  <ng-container *ngIf="investigation.SceneContact?.Emails?.length else noValue">
                    <app-email-phone-view
                      *ngFor="
                        let sceneEmail of investigation.SceneContact?.Emails
                      "
                      [phone]="sceneEmail"
                    ></app-email-phone-view>
                  </ng-container>
                </div>
              </div>
          </kendo-card-body>
        </kendo-card>
      </div>
      <div class="equal">
        <kendo-card class="p24" width="100%">
          <kendo-card-header>
            <h3 class="kendo-h3">Loss Details</h3>
          </kendo-card-header>
          <kendo-card-body>
            <div class="column">
              <!-- Loss Date -->
              <div class="equal">
                <p class="kendo-caption">Loss Date</p>
                <p class="kendo-caption-body">{{ investigation.lossDate | customDate : "MMMM d, y": true }}</p>
              </div>
              <!-- Risk Type -->
              <div class="equal">
                <p class="kendo-caption">Risk Type</p>
                <p class="kendo-caption-body" [innerHTML]="investigation.RiskType?.name"></p>
              </div>
              <!-- Loss Time -->
              <div class="equal">
                <p class="kendo-caption">Loss time</p>
                <p class="kendo-caption-body">{{ investigation.lossDate | customDate : "h:mm:ss a" : true }}</p>
              </div>
            </div>
            <div class="column">
              <!-- Claim # -->
              <div class="equal" *ngIf="investigation.Detail?.claimNumber">
                <p class="kendo-caption">Claim Number</p>
                <p class="kendo-caption-body" *ngIf="investigation.Detail?.claimNumber else noValue" [innerHTML]="investigation.Detail?.claimNumber"></p>
              </div>
              <!-- Policy # -->
              <div class="equal" *ngIf="investigation.Detail?.policyNumber">
                <p class="kendo-caption">Policy Number</p>
                <p class="kendo-caption-body" *ngIf="investigation.Detail?.policyNumber else noValue" [innerHTML]="investigation.Detail?.policyNumber"></p>
              </div>
               <!-- Description -->
               <div class="equal">
                <p class="kendo-caption">Description</p>
                <p class="kendo-caption-body" *ngIf="investigation.Detail?.riskDescription else noValue" [innerHTML]="investigation.Detail?.riskDescription"></p>
              </div>
            </div>
          </kendo-card-body>
        </kendo-card>
      </div>

      <!-- Investigation Address / Scene Exam Address / Scene Contact -->
      <div class="equal">
        <kendo-card class="p24" width="100%">
          <kendo-card-header>
            <h3 class="kendo-h3">Scene Exam Address</h3>
          </kendo-card-header>
          <kendo-card-body>
            <div class="column">
              <!-- Investigation Address -->
              <div class="equal">
                <p class="kendo-caption">Address</p>
                <app-address-view
                  [address]="investigation.InvestigationAddress"
                ></app-address-view>
              </div>
            </div>
          </kendo-card-body>
          <kendo-card-header>
            <hr />
          </kendo-card-header>
          <!-- Scene Contact -->
          <kendo-card-header>
            <h3 class="kendo-h3">Scene Contact</h3>
          </kendo-card-header>
          <kendo-card-body>
            <div class="column">
              <!-- Name -->
              <div class="equal">
                <p class="kendo-caption">Contact</p>
                <p>
                  <a
                    class="linkmd"
                    [routerLink]="[
                      '/contacts/',
                      investigation?.SceneContact?.id
                    ]"
                    target="_blank"

                    *ngIf="contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled"
                  >
                    <u *ngIf="investigation?.SceneContact else noValue" [innerHTML]="investigation?.SceneContact | fullName"></u>
                  </a>
                  <ng-container *ngIf="!(contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled)">
                    <ng-container
                      *ngTemplateOutlet="
                        contactName;
                        context: {
                          name: (investigation?.SceneContact | fullName)
                        }
                      "
                    ></ng-container>
                  </ng-container>
                </p>
              </div>
              <!-- Phone -->
              <div class="equal">
                <p class="kendo-caption">Phone</p>
                <ng-container *ngIf="investigation?.SceneContact?.Phones else noValue">
                  <app-email-phone-view
                    *ngFor="let phone of investigation?.SceneContact?.Phones"
                    [phone]="phone"
                  >
                  </app-email-phone-view>
                </ng-container>
              </div>
               <!-- Email -->
               <div class="equal">
                <p class="kendo-caption">Email</p>
                <ng-container *ngIf="investigation?.SceneContact?.Emails else noValue">
                  <app-email-phone-view
                    *ngFor="let email of investigation?.SceneContact?.Emails"
                    [email]="email"
                  ></app-email-phone-view>
                </ng-container>
              </div>
            </div>
            <div class="column">
              <!-- Address -->
              <div class="equal">
                <p class="kendo-caption">Address</p>
                <ng-container *ngIf="investigation?.SceneContact?.Addresses else noValue">
                  <app-address-view
                    *ngFor="let address of investigation?.SceneContact?.Addresses"
                    [address]="address"
                  ></app-address-view>
                </ng-container>
              </div>
              <!-- Special Instructions -->
              <div class="equal">
                <p class="kendo-caption">Special Billing Instructions</p>
                <p class="kendo-caption-body" *ngIf="investigation?.Detail?.specialInstructions else noValue" [innerHTML]="investigation?.Detail?.specialInstructions"></p>
              </div>
            </div>
          </kendo-card-body>
        </kendo-card>
      </div>
      <div class="equal">
        <kendo-card class="p24" width="100%">
          <kendo-card-header>
            <h3 class="kendo-h3">Notes</h3>
          </kendo-card-header>
          <kendo-card-body>
            <!-- Notes -->
            <p class="kendo-caption" *ngIf="investigation.Detail?.notes else noValue" [innerHTML]="investigation.Detail?.notes"></p>
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
  </div>

  <!-- Billing Information -->
  <div class="contentWrapper"
    *ngIf="(investigation?.billingInstructions || investigation?.Detail?.budgetAmount) && investigationBillingInformationView?.enabled">
    <div class="h-action">
      <h2 class="mb-0">Investigation Billing Information</h2>
      <i class="fa fa-pen" *ngIf="investigationBillingInformationUpdate?.enabled" (click)="editDetails('editBillingInfo')"></i>
    </div>
    <div class="flexContainer">
      <!-- Vehicle Info -->
      <div class="equal">
        <kendo-card class="p24" width="100%">
          <kendo-card-header>
            <h3 class="kendo-h3">Investigation Billing Information</h3>
          </kendo-card-header>
          <kendo-card-body>
            <div class="column">
              <div class="equal">
                <p class="kendo-caption">Budget Amount:</p>
                <p class="kendo-caption-body">{{ investigation?.Detail?.budgetAmount | currency }}</p>
              </div>
            </div>
            <div class="column">
              <div class="equal">
                <p class="kendo-caption">Investigation Billing Instructions:</p>
                <p class="kendo-caption-body wrap-white">{{ investigation?.billingInstructions || '-' }}</p>
              </div>
            </div>
          </kendo-card-body>
        </kendo-card>
      </div>
      <!-- Notes -->
      <div class="equal">
        <kendo-card class="p24" width="100%">
          <kendo-card-header>
            <h3 class="kendo-h3">Notes</h3>
          </kendo-card-header>
          <kendo-card-body>
            <p class="kendo-caption" *ngIf="investigation?.Detail?.billingInstructionNotes else noValue" [innerHTML]="investigation?.Detail?.billingInstructionNotes"></p>
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
  </div>

  <!-- Linked Investigations -->
  <div
    class="contentWrapper"
    *ngIf="investigation.LinkedInvestigations?.length && investigationLinkedInvestigationView?.enabled"
  >
    <div class="h-action">
      <h2 class="mat-h2">Linked Investigations</h2>
      <button mat-icon-button (click)="editDetails('editLinkedInvestigation')" *ngIf="investigationLinkedInvestigationUpdate?.enabled">
        <mat-icon color="primary">edit</mat-icon>
      </button>
    </div>
    <div class="flexContainer">
      <div class="equal">
        <mat-card class="mat-elevation-z1">
          <h3 class="mat-h3">Investigation</h3>
          <mat-card-content class="dashboard-card-content">
            <div class="infofield leftpad">
              <p class="mat-caption">Investigation ID</p>
              <a
                *ngFor="
                  let linkedInvestigation of investigation.LinkedInvestigations
                "
                [routerLink]="['/investigations', linkedInvestigation.id]"
                target="_blank"
              >
                {{ linkedInvestigation.nefcoNumber }}
              </a>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- Notes -->
      <div class="equal" *ngIf="investigation.Detail?.linkedInvestigationNotes">
        <mat-card class="mat-elevation-z1">
          <h3 class="mat-h3">Notes</h3>
          <mat-card-content>
            <p [innerHTML]="investigation.Detail?.linkedInvestigationNotes"></p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <!-- Parties -->
  <div class="contentWrapper" *ngIf="investigationPartiesListView?.enabled">
    <div class="h-action">
        <h2 class="mb-0">Parties</h2>
        <i class="fa fa-pen" *ngIf="investigationPartiesUpdate?.enabled" (click)="editDetails('editParties')"></i>
    </div>
    <div class="flexContainer">
      <div class="equal">
        <ng-container>
          <kendo-card class="investigation-details p24" width="100%">
            <kendo-card-header>
              <h3 class="kendo-h3">Insured</h3>
            </kendo-card-header>
            <kendo-card-body>
              <div class="column">
                <!-- Insured Type -->
                <div class="equal">
                  <p class="kendo-caption">Type</p>
                  <kendo-badge
                    class="fw600"
                    position="none"
                    size="medium"
                    rounded="full"
                    themeColor="primary"
                    >{{
                      investigation?.Insured?.contactName
                        ? "Business"
                        : "Individual"
                    }}</kendo-badge
                  >
                </div>

                <!-- Company Name -->
                <div class="equal" *ngIf="investigation?.Insured?.contactName">
                  <p class="kendo-caption">Company Name</p>
                  <p class="kendo-caption-body">
                    <span
                      [innerHTML]="investigation?.Insured.insuredName || '-'"
                    ></span>
                  </p>
                </div>

                <!-- Contact Name -->
                <div class="equal" *ngIf="investigation?.Insured?.contactName">
                  <p class="kendo-caption">Contact Name</p>
                  <p class="kendo-caption-body">
                    <span
                      [innerHTML]="investigation?.Insured?.contactName || '-'"
                    ></span>
                  </p>
                </div>

                <!-- Name -->
                <div class="equal" *ngIf="!investigation?.Insured?.contactName">
                  <p class="kendo-caption">Insured Name</p>
                  <p class="kendo-caption-body">
                    <span
                      [innerHTML]="investigation?.Insured?.insuredName || '-'"
                    ></span>
                  </p>
                </div>

                <!-- Insured Relationship/Title -->
                <div class="equal">
                  <p class="kendo-caption">Relationship / Title</p>
                  <p
                    class="kendo-caption-body"
                    [innerHTML]="investigation?.Insured?.title || '-'"
                  ></p>
                </div>

                <!-- Email -->
                <div class="equal">
                  <p class="kendo-caption">Email</p>
                  <p class="kendo-caption-body">
                    <app-email-phone-view
                      *ngFor="let email of investigation?.Insured?.Emails"
                      [email]="email"
                    ></app-email-phone-view>
                    <span *ngIf="!investigation?.Insured?.Emails?.length"
                      >-</span
                    >
                  </p>
                </div>
              </div>

              <div class="column">
                <!-- Phone -->
                <div class="equal">
                  <p class="kendo-caption">Phone</p>
                  <p class="kendo-caption-body">
                    <app-email-phone-view
                      *ngFor="let phone of investigation?.Insured?.Phones"
                      [phone]="phone"
                    >
                    </app-email-phone-view>
                    <span *ngIf="!investigation?.Insured?.Phones?.length"
                      >-</span
                    >
                  </p>
                </div>
                <!-- Address -->
                <div class="equal">
                  <p class="kendo-caption">Address</p>
                  <p class="kendo-caption-body">
                    <app-address-view
                      *ngFor="let address of investigation?.Insured?.Addresses"
                      [address]="address"
                    ></app-address-view>
                  </p>
                </div>

                <div class="equal">
                  <p class="kendo-caption">Suffix</p>
                  <p class="kendo-caption-body">
                    {{ investigation?.Insured?.suffix || "-" }}
                  </p>
                </div>
              </div>
            </kendo-card-body>
          </kendo-card>
        </ng-container>
      </div>
      <div class="equal" *ngFor="let party of investigation.Parties">
        <!-- Parties-->
        <ng-container
          *ngIf="party?.Role?.name === 'Insured'; else otherParties"
        >
          <kendo-card class="investigation-details p24" width="100%">
            <kendo-card-header>
              <h3 class="kendo-h3">{{ party?.Role?.name }}</h3>
            </kendo-card-header>
            <kendo-card-body>
              <div class="column">
                <!-- Insured Type -->
                <div class="equal">
                  <p class="kendo-caption">Type</p>
                  <kendo-badge
                    class="fw600"
                    position="none"
                    size="medium"
                    rounded="full"
                    themeColor="primary"
                    >{{
                      party.Contact?.insuredName ? "Business" : "Individual"
                    }}</kendo-badge
                  >
                </div>

                <!-- Company Name -->
                <div class="equal" *ngIf="party.Contact?.insuredName">
                  <p class="kendo-caption">Company Name</p>
                  <p class="kendo-caption-body">
                    <span
                      [innerHTML]="party.Contact.insuredName"
                      *ngIf="contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled"
                    ></span>
                    <ng-container *ngIf="!(contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled)">
                      <ng-container
                        *ngTemplateOutlet="
                          contactName;
                          context: {
                            name: (party.Contact.insuredName | fullName)
                          }
                        "
                      ></ng-container>
                    </ng-container>
                  </p>
                </div>

                <!-- Contact Name -->
                <div class="equal" *ngIf="party.Contact?.insuredName">
                  <p class="kendo-caption">Contact Name</p>
                  <p class="kendo-caption-body">
                    <span
                      [innerHTML]="party.Contact | fullName"
                      *ngIf="contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled"
                    ></span>
                    <ng-container *ngIf="!(contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled)">
                      <ng-container
                        *ngTemplateOutlet="
                          contactName;
                          context: { name: (party.Contact | fullName) }
                        "
                      ></ng-container>
                    </ng-container>
                  </p>
                </div>

                <!-- Name -->
                <div class="equal" *ngIf="!party.Contact?.insuredName">
                  <p class="kendo-caption">Insured Name</p>
                  <p class="kendo-caption-body">
                    <span
                      [innerHTML]="party.Contact | fullName"
                      *ngIf="contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled"
                    ></span>
                    <ng-container *ngIf="!(contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled)">
                      <ng-container
                        *ngTemplateOutlet="
                          contactName;
                          context: { name: (party.Contact | fullName) }
                        "
                      ></ng-container>
                    </ng-container>
                  </p>
                </div>

                <!-- Insured Relationship/Title -->
                <div class="equal" *ngIf="party.Role.name === 'Insured'">
                  <p class="kendo-caption">Relationship / Title</p>
                  <p
                    class="kendo-caption-body"
                    [innerHTML]="party.Contact.nickName"
                  ></p>
                </div>

                <!-- Email -->
                <div class="equal">
                  <p class="kendo-caption">Email</p>
                  <p class="kendo-caption-body">
                    <app-email-phone-view
                      *ngFor="let email of party.Contact?.Emails"
                      [email]="email"
                    ></app-email-phone-view>
                    <span *ngIf="party.Contact?.Emails?.length === 0">-</span>
                  </p>
                </div>
              </div>

              <div class="column">
                <!-- Phone -->
                <div class="equal">
                  <p class="kendo-caption">Phone</p>
                  <p class="kendo-caption-body">
                    <app-email-phone-view
                      *ngFor="let phone of party.Contact?.Phones"
                      [phone]="phone"
                    >
                    </app-email-phone-view>
                  </p>
                </div>
                <!-- Address -->
                <div class="equal">
                  <p class="kendo-caption">Address</p>
                  <p class="kendo-caption-body">
                    <app-address-view
                      *ngFor="let address of party.Contact?.Addresses"
                      [address]="address"
                    ></app-address-view>
                  </p>
                </div>

                <div class="equal">
                  <p class="kendo-caption">Suffix</p>
                  <p class="kendo-caption-body">
                    {{ party.Contact.suffix || "-" }}
                  </p>
                </div>
              </div>
            </kendo-card-body>
          </kendo-card>
        </ng-container>
        <ng-template #otherParties>
          <kendo-card class="p24" width="100%">
            <kendo-card-header>
              <h3 class="kendo-h3">{{ party?.Role?.name }}</h3>
            </kendo-card-header>
            <kendo-card-body>
              <div class="column">
                <!-- Insured Name -->
                <div class="equal" *ngIf="party.Contact?.insuredName">
                  <p class="kendo-caption">Insured Name</p>
                  <p>
                    <a
                      class="linkmd"
                      [routerLink]="['/contacts/', party.Contact?.id]"
                      target="_blank"
                      [innerHTML]=""
                      *ngIf="contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled"
                    >
                      <u *ngIf="party?.Contact?.insuredName else noValue">{{party?.Contact?.insuredName}}</u>
                    </a>
                    <ng-container *ngIf="!(contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled)">
                      <ng-container
                        *ngTemplateOutlet="
                          contactName;
                          context: {
                            name: (party.Contact.insuredName | fullName)
                          }
                        "
                      ></ng-container>
                    </ng-container>
                  </p>
                </div>
                <!-- Name -->
                <div class="equal">
                  <p class="kendo-caption">Name</p>
                  <p>
                    <a
                      class="linkmd"
                      [routerLink]="['/contacts/', party.Contact?.id]"
                      target="_blank"
                      *ngIf="contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled"
                    >
                      <u *ngIf="party?.Contact else noValue">{{party?.Contact | fullName}}</u>
                    </a>
                    <ng-container *ngIf="!(contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled)">
                      <ng-container
                        *ngTemplateOutlet="
                          contactName;
                          context: { name: (party.Contact | fullName) }
                        "
                      ></ng-container>
                    </ng-container>
                  </p>
                </div>
                <!-- Insured Relationship/Title -->
                <div class="equal" *ngIf="party?.Role?.name === 'Insured'">
                  <p class="kendo-caption">Relationship / Title</p>
                  <p class="kendo-caption-body" If="party?.Contact?.nickName else noValue" [innerHTML]="party.Contact.nickName"></p>
                </div>
                <!-- Email -->
                <div class="equal">
                  <p class="kendo-caption">Email</p>
                  <ng-container *ngIf="party.Contact?.Emails?.length else noValue">
                    <app-email-phone-view
                      *ngFor="let email of party.Contact?.Emails"
                      [email]="email"
                    ></app-email-phone-view>
                  </ng-container>
                </div>
              </div>
              <div class="column">
                <!-- Phone -->
                <div class="equal">
                  <p class="kendo-caption">Phone</p>
                  <ng-container *ngIf="party.Contact?.Phones?.length else noValue">
                    <app-email-phone-view
                      *ngFor="let phone of party.Contact?.Phones"
                      [phone]="phone"
                    >
                    </app-email-phone-view>
                  </ng-container>
                </div>
                <!-- Address -->
                <div class="equal">
                  <p class="kendo-caption">Address</p>
                  <ng-container *ngIf="party.Contact?.Addresses?.length else noValue">
                    <app-address-view
                      *ngFor="let address of party.Contact?.Addresses"
                      [address]="address"
                    ></app-address-view>
                  </ng-container>
                </div>
              </div>
            </kendo-card-body>
          </kendo-card>
        </ng-template>
      </div>
      <!-- Notes -->
      <div class="equal" *ngIf="investigation.Detail?.partyNotes">
        <kendo-card class="p24" width="100%">
          <kendo-card-header>
            <h3 class="kendo-h3">Notes</h3>
          </kendo-card-header>
          <kendo-card-body>
            <!-- Notes -->
            <p class="kendo-caption" *ngIf="investigation.Detail?.partyNotes else noValue" [innerHTML]="investigation.Detail?.partyNotes"></p>
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
  </div>

  <!-- Staff Section -->
  <div class="contentWrapper" *ngIf="investigationAssignStaffListView?.enabled">
    <div class="h-action">
        <h2 class="mb-0">Staff</h2>
        <a [routerLink]="['/admin', 'investigations', 'edit', this.investigation.id]" [queryParams]="{editStaff: true}" target="_blank" *ngIf="investigationAssignStaffUpdate?.enabled || investigationAssignStaffCreate?.enabled">
          <i class="fa fa-pen"></i>
        </a>
    </div>
    <div class="flexContainer">
      <!-- loop over and create as many as necessary - investigationusers -->
      <div
        class="equal"
        *ngFor="let person of investigation.InvestigationStaff"
      >
        <kendo-card class="p24" width="100%">
          <kendo-card-header>
            <h3 class="kendo-h3">{{person.Role?.title | quiet}} <ng-container *ngIf="person.isPrimary">(Primary)</ng-container></h3>
          </kendo-card-header>
          <kendo-card-body>
            <div class="column">
              <div class="equal">
                <p class="kendo-caption">Contact:</p>
                <p
                  class="kendo-caption-body"
                  [appEnforcePermission]="authConfig.staffViewAll"
                  #adminPerm="appEnforcePermission"
                >
                  <a
                    class="linkmd"
                    *ngIf="adminPerm.enabled; else nonadmin"
                    [routerLink]="['/staff/', person.User?.id]"
                    target="_blank"
                  >
                    <u [innerHTML]="person.User | fullName"></u>
                  </a>
                  <ng-template #nonadmin>
                    <p class="kendo-caption-body">{{ person.User | fullName }}</p>
                  </ng-template>
                </p>
              </div>
              <div class="equal">
                <p class="kendo-caption">Email</p>
                <ng-container *ngIf="person.User?.Emails?.length else noValue">
                  <app-email-phone-view
                    *ngFor="let userEmail of person.User?.Emails"
                    [email]="userEmail"
                  ></app-email-phone-view>
                </ng-container>
              </div>
            </div>
            <div class="column">
              <div class="equal">
                <p class="kendo-caption">Phone</p>
                <ng-container *ngIf="person?.User?.Phones?.length else noValue">
                  <app-email-phone-view
                    *ngFor="let personPhone of person.User?.Phones"
                    [phone]="personPhone"
                  ></app-email-phone-view>
                </ng-container>
              </div>
            </div>
          </kendo-card-body>
        </kendo-card>
      </div>
      <!-- Notes -->
      <div class="equal" *ngIf="investigation.Detail?.staffNotes">
        <kendo-card class="p24" width="100%">
          <kendo-card-header>
            <h3 class="kendo-h3">Notes</h3>
          </kendo-card-header>
          <kendo-card-body>
            <!-- Notes -->
            <p class="kendo-caption" *ngIf="investigation.Detail?.staffNotes else noValue" [innerHTML]="investigation.Detail?.staffNotes"></p>
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
  </div>

  <!-- Vehicles Section -->
  <div class="contentWrapper" *ngIf="investigation.Vehicles?.length && investigationVehiclesListView?.enabled">
    <div class="h-action">
      <h2 class="mb-0">Vehicles</h2>
      <i class="fa fa-pen" (click)="editDetails('editVehicles')" *ngIf="investigationVehiclesUpdate?.enabled"></i>
    </div>
    <div class="flexContainer">
      <!-- Vehicle Info -->
      <div class="equal" *ngFor="let vehicle of investigation.Vehicles">
        <kendo-card class="p24" width="100%">
          <kendo-card-header>
            <h3 class="kendo-h3">Vehicle</h3>
          </kendo-card-header>
          <kendo-card-body>
            <div class="column">
              <div class="equal">
                <p class="mat-caption">Make:</p>
                <p>{{ vehicle.make }}</p>
              </div>

              <div class="equal">
                <p class="mat-caption">Model:</p>
                <p>{{ vehicle.model }}</p>
              </div>
            </div>
            <div class="column">
              <div class="equal">
                <p class="mat-caption">VIN:</p>
                <p>{{ vehicle.vinNumber }}</p>
              </div>

              <div class="equal">
                <p class="mat-caption">Year:</p>
                <p>{{ vehicle.year }}</p>
              </div>
            </div>
          </kendo-card-body>
        </kendo-card>
      </div>
      <!-- Notes -->
      <div class="equal">
        <kendo-card class="p24" width="100%">
          <kendo-card-header>
            <h3 class="kendo-h3">Notes</h3>
          </kendo-card-header>
          <kendo-card-body>
            <!-- Notes -->
            <p class="kendo-caption" *ngIf="investigation.Detail?.vehicleNotes else noValue" [innerHTML]="investigation.Detail?.vehicleNotes"></p>
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
  </div>

  <!-- On Scene Investigation Dates Section -->
  <div class="contentWrapper"  *ngIf="investigationScheduledDateView?.enabled">
    <div class="h-action">
      <h2 class="mb-0">On Scene Investigation Dates</h2>
      <i class="fa fa-pen" (click)="editScheduledDate('EDIT_PENDING_SCHEDULE')" *ngIf="investigation?.InvestigationStaff?.length && (investigationScheduledDateUpdateAll?.enabled || investigationScheduledDateUpdateOwn?.enabled)"></i>
  </div>
    <div class="flexContainer">
      <!-- Vehicle Info -->
      <div class="equal">
        <kendo-card class="p24" width="100%">
          <kendo-card-header>
            <h3 class="kendo-h3">Pending Scene Dates</h3>
          </kendo-card-header>
          <kendo-card-body>
            <div class="column">
              <div class="equal">
                <p class="kendo-caption">Name</p>
                <ng-container *ngIf="pendingCompletedInvestigationScheduledEntry?.pending?.length else noValue">
                  <p class="kendo-caption-body" *ngFor="let onScene of pendingCompletedInvestigationScheduledEntry?.pending"> {{onScene?.User | fullName}}</p>
                </ng-container>
              </div>
            </div>
            <div class="column">
              <div class="equal">
                <p class="kendo-caption">Date/Time</p>
                <ng-container *ngIf="pendingCompletedInvestigationScheduledEntry?.pending?.length else noValue">
                  <p class="kendo-caption-body" *ngFor="let onScene of pendingCompletedInvestigationScheduledEntry?.pending">{{ onScene?.scheduledDate ? ((onScene?.scheduledDate | dateWithTimezone : "MMMM DD, YYYY" : investigation?.timezone) + ' at ' + (onScene?.scheduledDate | dateWithTimezone : "hh:mm a": investigation?.timezone)) : '-'}} ({{investigation?.timezone || ''}})</p>
                </ng-container>
              </div>
            </div>
          </kendo-card-body>
        </kendo-card>
      </div>
      <!-- Notes -->
      <div class="equal">
        <kendo-card class="p24" width="100%">
          <kendo-card-header>
            <h3 class="kendo-h3">Completed Scene Dates</h3>
          </kendo-card-header>
          <kendo-card-body>
            <div class="column">
              <div class="equal">
                <p class="kendo-caption">Name</p>
                <ng-container *ngIf="pendingCompletedInvestigationScheduledEntry?.completed?.length else noValue">
                  <p *ngFor="let onScene of pendingCompletedInvestigationScheduledEntry?.completed"> {{onScene?.User | fullName}}</p>
                </ng-container>
              </div>
            </div>
            <div class="column">
              <div class="equal">
                <p class="kendo-caption">Date/Time</p>
                <ng-container *ngIf="pendingCompletedInvestigationScheduledEntry?.completed?.length else noValue">
                  <p *ngFor="let onScene of pendingCompletedInvestigationScheduledEntry?.completed"> {{ onScene?.scheduledDate ? ((onScene?.scheduledDate | dateWithTimezone : "MMMM DD, YYYY": investigation?.timezone) + ' at ' + (onScene?.scheduledDate | dateWithTimezone : "hh:mm a": investigation?.timezone)) : '-'}} ({{investigation?.timezone || ''}})</p>
                </ng-container>
              </div>
            </div>
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
  </div>

</section>

<ng-template #noValue>
  <p class="kendo-caption-body">-</p>
</ng-template>

<ng-template #contactName let-name="name">
  <p class="kendo-caption-body">{{name || '-' }}</p>
</ng-template>

<app-reject-reactivate-modal
  *ngIf="investigationRejectReactivateModal"
  [opened]="investigationRejectReactivateModal"
  [mode]="investigationRejectReactivateModalMode"
  [id]="investigation?.id"
  [headerIcon]="'assets/svg/warning.svg'"
  (closeModel)="investigationRejectReactivateModalResponse($event)"
></app-reject-reactivate-modal>

<kendo-dialog
  *ngIf="isEditReceivedModal"
  #receivedDateDialog
  class="receievdDateModal"
  (close)="openRecivedDateModal(false);"
  [minWidth]="408"
  [width]="408"
  [dialogRef]="receivedDateDialog" (appClickOutside)="openRecivedDateModal(false);"
>
    <kendo-dialog-titlebar>
      <div class="modal-title">
        <span>Edit Received Date</span>
      </div>
    </kendo-dialog-titlebar>

    <div class="model-body mt-24 mb-24">
      <form #receivedDateForm="ngForm">
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
          <kendo-gridlayout-item class="mb-0">
            <kendo-formfield>
              <kendo-label class="asterisk" text="Received Date"></kendo-label>
              <kendo-datetimepicker
                  calendarType="classic"
                  [fillMode]="'outline'"
                  name="receivedDate"
                  #receivedDate="ngModel"
                  [(ngModel)]="recievedDate"
                  placeholder="Enter date"
                  format="MM-dd-yyyy hh:mm a"
                  formatPlaceholder="formatPattern"
                  [disabledDates]="disabledDates"
                  required
              ></kendo-datetimepicker>
              <kendo-formerror *ngIf="!receivedDate?.valid && (receivedDate?.dirty || receivedDate?.touched)">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="receivedDate?.errors?.required">
                    Received Date cannot be left blank
                  </span>
                  <span *ngIf="!receivedDate?.errors?.required && receivedDate?.errors">
                    Received Date is invalid
                  </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </form>
    </div>

    <kendo-dialog-actions layout="end">
      <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="openRecivedDateModal(false);">
        Cancel
      </button>
      <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="editReceivedDate();">
        Save Edits
      </button>
    </kendo-dialog-actions>
</kendo-dialog>
