<div [dialogRef]="dialog" (appClickOutside)="close()">
  <div [appEnforcePermission]="authConfig.investigationScheduledDateUpdateOwn"
    #investigationScheduledDateUpdateOwn="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.investigationScheduledDateUpdateAll"
    #investigationScheduledDateUpdateAll="appEnforcePermission"></div>

  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span *ngIf="mode === 'NEXT_SCHEDULE'">Edit Scheduled Date</span>
      <span *ngIf="mode === 'EDIT_PENDING_SCHEDULE_CALENDAR'">Edit On Scene Exam Investigation</span>
      <span *ngIf="mode === 'NEW_SCHEDULE'">Schedule On Scene Investigation Date</span>
      <span *ngIf="mode === 'EDIT_PENDING_SCHEDULE'">Edit On Scene Investigation Date</span>
      <span *ngIf="mode === 'NEW_SCHEDULE_CALENDAR'">Add New On Scene Exam Investigation</span>
      <ng-container *ngIf="investigation?.nefcoNumber">
        <p class="modal-subtitle mb-12 mt-12">Investigation ID: {{investigation?.nefcoNumber}}</p>
        <p class="modal-subtitle mb-12">Timezone: {{(investigation?.timezone | timezonePipe) || '-'}}</p>
      </ng-container>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body">

    <kendo-gridlayout class="contact-info-wrapper mt-16 mb-16" [rows]="['auto']" [gap]="{rows: 5, cols: 0}"
      [cols]="['repeat(5,minmax(0,1fr))']" *ngIf="investigation?.InsuredContacted?.id">
      <kendo-gridlayout-item class="mb-0 p-16">
          <div>
            <h6>Staff Member</h6>
            <p class="staff-name">{{ investigation?.InsuredContacted?.User | fullName : false }}</p>
          </div>
         
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="mb-0 p-16" colSpan="4">
        <div>
          <h6>Contact Date & Time</h6>
          <p class="date-time">
            {{ investigation?.InsuredContacted?.date | dateWithTimezone : "MM-DD-YYYY" : investigation?.timezone }} at
            {{ investigation?.InsuredContacted?.date | dateWithTimezone : 'hh:mm:ss A' : investigation?.timezone }}
          </p>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <p class="warning-message mb-16 mt-16">
      Enter the scheduled date and time of when you will be arriving on scene to the investigation.<br />
    </p>
    <form #frm="ngForm" autocomplete="off">
      <section *ngIf="scheduledFormEntry?.length">
        <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }"
          [cols]="investigationScheduledDateUpdateAll?.enabled ? ['repeat(7,minmax(0,1fr))'] : ['repeat(5,minmax(0,1fr))'] ">
          <ng-container
            *ngFor="let item of scheduledFormEntry; let index = index; let first = first; let last = last trackBy: trackByIndex">
            <kendo-gridlayout-item colSpan="2" *ngIf="investigationScheduledDateUpdateAll?.enabled">
              <kendo-formfield>
                <kendo-label class="asterisk" text="Staff Member"></kendo-label>
                <kendo-dropdownlist [name]="'staffMemberSelect_' + index" #staffMemberSelect="ngModel"
                  [data]="staffList" textField="userName" valueField="userId" fillMode="outline"
                  [defaultItem]="{userName:  'Select staff member', userId: null}" [valuePrimitive]="true"
                  [(ngModel)]="scheduledFormEntry[index].UserId" [popupSettings]="{
                  appendTo: 'component',
                  animate: false,
                  width: 250
                }" required>
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span *ngIf="dataItem?.userName === 'Select staff member'" class="placeholder">
                      {{ dataItem?.userName }}
                    </span>
                    <span *ngIf="dataItem?.userName !== 'Select staff member'" class="not-placeholder">
                      {{ dataItem?.userName }}
                    </span>
                  </ng-template>
                </kendo-dropdownlist>
                <kendo-formerror
                  *ngIf="!staffMemberSelect?.valid && (staffMemberSelect?.dirty || staffMemberSelect?.touched)">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="staffMemberSelect?.errors?.required">
                    Staff member cannot be left blank
                  </span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item colSpan="2">
              <kendo-formfield>
                <kendo-label class="asterisk" text="Scheduled Date"></kendo-label>
                <kendo-datepicker [min]="currentDate" calendarType="classic" [fillMode]="'outline'"
                  [name]="'date_' + index" #scheduledDate="ngModel"
                  [(ngModel)]="scheduledFormEntry[index].scheduledDate" placeholder="Enter date" format="MM/dd/yyyy"
                  required="true" formatPlaceholder="formatPattern"></kendo-datepicker>
                <kendo-formerror>
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="!scheduledDate?.valid && (scheduledDate?.dirty || scheduledDate?.touched)">Date cannot be
                    left
                    blank</span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item colSpan="2">
              <kendo-formfield>
                <kendo-label class="asterisk" text="Scheduled Time"></kendo-label>
                <kendo-timepicker required="true" format="hh:mm a" [name]="'time_' + index" #scheduledTime="ngModel"
                  [(ngModel)]="scheduledFormEntry[index].scheduledTime" placeholder="Enter time"></kendo-timepicker>
                <kendo-formhint>(ie, HH:MM)</kendo-formhint>
                <kendo-formerror>
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span *ngIf="!scheduledTime?.valid && (scheduledTime?.dirty || scheduledTime?.touched)">Time cannot be
                    left blank</span>
                </kendo-formerror>
              </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="actions" colSpan="1">
              <span class="k-icon k-i-minus-outline" style="margin-top: 33px;margin-left: 16px;"
                *ngIf="mode !== 'NEW_SCHEDULE_CALENDAR' && mode !== 'EDIT_PENDING_SCHEDULE_CALENDAR'"
                (click)="removeScheduledEntry(index)"></span>
            </kendo-gridlayout-item>
          </ng-container>
        </kendo-gridlayout>
      </section>
    </form>
    <button kendoButton class="mr-24" icon="plus-circle" themeColor="primary" fillMode="link"
      *ngIf="mode !== 'NEW_SCHEDULE_CALENDAR' && mode !== 'EDIT_PENDING_SCHEDULE_CALENDAR'"
      (click)="addScheduledEntry()">Add Another Scene Investigation
      Date</button>
    <br *ngIf="mode !== 'NEW_SCHEDULE_CALENDAR' && mode !== 'EDIT_PENDING_SCHEDULE_CALENDAR'" />
    <br *ngIf="mode !== 'NEW_SCHEDULE_CALENDAR' && mode !== 'EDIT_PENDING_SCHEDULE_CALENDAR'" />
  </div>
  <kendo-dialog-actions class="schedule-action p-16-24" layout="end" [ngStyle]="mode === 'EDIT_PENDING_SCHEDULE_CALENDAR' && {'justify-content': 'space-between'}">
    <kendo-button *ngIf="mode === 'EDIT_PENDING_SCHEDULE_CALENDAR'" class="link-button" kendoButton themeColor="none"
      (click)="deleteSingle()" fillMode="link" size="medium" iconClass="fa-solid fa-trash">Delete On
      Scene Exam Investigation</kendo-button>
    <div>
      <button kendoButton [size]="'medium'" themeColor="light" class="kendo-btn" class="mr-16" (click)="close()">
        Discard Edits
      </button>
      <button kendoButton class="kendo-btn" [disabled]="!frm?.valid" [size]="'medium'" themeColor="primary"
        (click)="submit()">
        Save Edits & Notify Client
      </button>
    </div>
  </kendo-dialog-actions>
</div>
