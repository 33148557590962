import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { GoogleMapService } from '../../modules/google-maps/google-map.service';
import { StateService } from 'src/app/shared/services/state.service';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AddressService, AddressTypeService } from '../../services';
import { IApiAddress, IApiAddressType, IApiAddressTypeOrderBy, IApiUpdateAddressInput } from '../../modules/graphql/types/types';
import { removeTypename, unwrapConnection } from '../../rxjs.pipes';
import { debounceTime } from 'rxjs/operators';
import { SortOrder } from '../../modules/graphql/enums/generic.enums';

// These are the "types" returned in the "Address Component" of a "Place" object from Google's API
export enum AddressType {
  STREET_NUMBER = "street_number",
  STREET = "route",
  STREET2 = "subpremise",
  CITY = "locality",
  CITY2 = "administrative_area_level_3",
  CITY3 = "sublocality_level_1",
  STATE = "administrative_area_level_1",
  POSTAL_CODE = "postal_code",
  COUNTRY = "country"
}

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss']
})

export class AddressAutocompleteComponent implements OnInit, AfterViewInit, OnChanges {

  private _address = null;
  public isCustomAddress = false;

  // Only set "loadAPI" to "true" if you have once instance of Google Maps.
  // If you have more than one, follow investigation-create pattern and load in parent
  @Input() loadAPI: boolean;
  @Input() showType: boolean;
  @Input() defaultType: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Output() setTimezone: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;
  @Input() public set address(val) {
    this._address = val;
  }
  @Input() set resetForm(val: boolean) {
    if (val) {
      this.isCustomAddress = false;
      this.clearAddress();
      this.disableControls();
    }
  }
  public get address() {
    return this._address;
  }
  queryWait: boolean;

  public addressTypes: IApiAddressType[] = [];

  public addressForm = this.fb.group({
    id: null,
    TypeId: [null, Validators.required],
    address1: [null, Validators.required],
    address2: [null],
    city: [null, Validators.required],
    state: [null, Validators.required],
    postal: [null, [
      Validators.required,
      Validators.pattern(/^\d+$/),
      Validators.minLength(5),
      Validators.maxLength(5)
    ]],
    latitude: [null],
    longitude: [null],
    country: ["United States"],
    addressSearch: [null]
  });

  public initialValues = this.addressForm.value;

  public onChange: any = (_) => { };
  public onTouch: any = () => { };

  constructor(
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private _mapService: GoogleMapService,
    private addressTypeService: AddressTypeService,
    public stateService: StateService,
  ) {
    this.disableControls();
    this.addressTypeService.get([], { sortOrder: SortOrder.ASC, orderBy: IApiAddressTypeOrderBy.Name }).pipe(
      unwrapConnection(),
      removeTypename()
    ).subscribe((types) => {
      this.addressTypes = types;
      this.setAddressType();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // adding this patch value to the setter causes re-renders, listen for changes here so we can update the form data as needed
    const { address } = changes;
    if (address) {
      if (!address?.currentValue?.latitude && !address?.currentValue?.longitude && address.currentValue && !address?.previousValue) {
        const addressInfo = [];
        if (address?.currentValue?.address1) addressInfo.push(address?.currentValue?.address1);
        if (address?.currentValue?.address2) addressInfo.push(address?.currentValue?.address2);
        if (address?.currentValue?.city) addressInfo.push(address?.currentValue?.city);
        if (address?.currentValue?.state) addressInfo.push(address?.currentValue?.state);
        if (address?.currentValue?.postal) addressInfo.push(address?.currentValue?.postal);
        const addressSearch = addressInfo.join(', ');
        this.addressForm.patchValue({ addressSearch, ...address.currentValue });
      } else {
        this.addressForm.patchValue(address.currentValue);
      }
    }
  }

  // Extracts the needed address components from Google's Places API
  private extractAddress(components, type) {
    return components
      .filter((component) => component.types.indexOf(type) === 0)
      .map((item) => (type === AddressType.STATE) ? item.short_name : item.long_name).pop() || '';
  }

  // Might need more work - finds the "AddressType" from db
  private setAddressType() {
    const typeId = this.addressTypes.find((e) => e.name === this.defaultType)?.id || this.addressTypes[0].id;
    if (!this.addressForm.controls.TypeId.value) {
      this.addressForm.patchValue({ TypeId: typeId });
    }
  }

  private setAddressId() {
    if (this.address?.id) {
      this.addressForm.patchValue({ id: this.address?.id });
    }
  }

  ngOnInit() {
    // Emit address when user searches or manually inputs one
    this.addressForm.valueChanges.pipe(debounceTime(500)).subscribe(val => {
      // Ensure form is valid if the user is using "custom" address
      // OR ensure user has selected an address from Google autocomplete
      if (this.addressForm.valid && !!this.addressForm.get('address1').value) {
        this.invokeEvent(this.addressFormatted());
      }
    });
  }

  ngAfterViewInit() {
    // Only load API if this is used once on a page. Alternatively, if we can load the API globally, we can do away with this.
    if (this.loadAPI) {
      this._mapService.loadGoogleMapApi().subscribe(() => {
        this.getPlaceAutocomplete();
      });
    }
    else {
      this.getPlaceAutocomplete();
    }
  }

  // Google Address Auto Complete - find address and emit value to parent
  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        componentRestrictions: { country: 'US' }
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this._mapService.getTimezone(place.geometry.location.lat(), place.geometry.location.lng()).subscribe(result => {
        this.setTimezone.emit(result);
      }, error => {
        console.log(error);
      });
      const foundAddress = {
        address1: `${this.extractAddress(place.address_components, AddressType.STREET_NUMBER)} ${this.extractAddress(place.address_components, AddressType.STREET)}`,
        address2: this.extractAddress(place.address_components, AddressType.STREET2),
        city: this.extractAddress(place.address_components, AddressType.CITY) || this.extractAddress(place.address_components, AddressType.CITY2) || this.extractAddress(place.address_components, AddressType.CITY3),
        state: this.extractAddress(place.address_components, AddressType.STATE),
        postal: this.extractAddress(place.address_components, AddressType.POSTAL_CODE),
        country: this.extractAddress(place.address_components, AddressType.COUNTRY),
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng()
      };
      this.addressForm.patchValue(foundAddress);
      // Redraw address form
      this.ref.detectChanges();
    });
  }

  // Remove "addressSearch" field
  public addressFormatted() {
    const currentAddress = this.addressForm.getRawValue();
    delete currentAddress.addressSearch;
    return currentAddress;
  }

  // Clear address search and emit empty address to parent
  public clearAddress() {
    this.addresstext.value = null;
    this.addressForm.reset(this.initialValues);
    this.setAddressType();
    this.setAddressId();
    this.invokeEvent(this.addressFormatted());
  }

  // Method to send address to parent
  private invokeEvent(address: IApiAddress) {
    this.setAddress.emit(address);
  }

  // Disable controls for address autocomplete
  private disableControls() {
    this.addressForm.controls.address1.disable();
    this.addressForm.controls.city.disable();
    this.addressForm.controls.state.disable();
    this.addressForm.controls.postal.disable();
    this.addressForm.controls.addressSearch.enable();
  }

  // Enable controls for manual/custom address entry
  private enableControls() {
    this.addressForm.controls.address1.enable();
    this.addressForm.controls.city.enable();
    this.addressForm.controls.state.enable();
    this.addressForm.controls.postal.enable();
    this.addressForm.controls.addressSearch.disable();
  }

  // Toggle input controls between search and custom address entry
  public manualAddress({ checked }) {
    this.isCustomAddress = checked;
    this.clearAddress();
    !!checked ? this.enableControls() : this.disableControls();
  }

  private getAddressFromZipCode(zip) {
    this._mapService.getAddressByZip(zip).subscribe(result => {
      if (result?.length && result[0]?.geometry.location.lat && result[0]?.geometry?.location?.lng) {
        this._mapService.getTimezone(result[0]?.geometry.location.lat, result[0]?.geometry.location.lng).subscribe(result => {
          this.setTimezone.emit(result);
        }, error => {
          console.log(error);
        });
      }
    }, error => {
      console.log(error.message || 'Zip code is not valid');
    })
  }

  public zipChanged(e) {
    if (e.target.value)
      this.getAddressFromZipCode(e.target.value);
  }
}

