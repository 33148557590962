<div [appEnforcePermission]="authConfig.investigationScheduledDateView"
  #investigationScheduledDateView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationScheduledDateUpdateAll"
  #investigationScheduledDateUpdateAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationScheduledDateUpdateOwn"
  #investigationScheduledDateUpdateOwn="appEnforcePermission"></div>
<kendo-gridlayout class="p-8 navbar-grid" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
  [cols]="['repeat(4,minmax(0,1fr))']">
  <kendo-gridlayout-item class="mb-0">
    <button kendoButton themeColor="primary" (click)="navigate('today')" fillMode="solid">Today</button>
    <kendo-buttongroup class="ml-16">
      <button kendoButton themeColor="light" class="bg-white" fillMode="solid" iconClass="fa fa-caret-left"
        (click)="navigate('prev')"></button>
      <button kendoButton themeColor="light" class="bg-white" fillMode="solid" iconClass="fa fa-caret-right"
        (click)="navigate('next')"></button>
    </kendo-buttongroup>
  </kendo-gridlayout-item>
  <!-- <kendo-gridlayout-item class="mb-0 navbar-date" *ngIf="from !== 'INVESTIGATION_DETAILS'">
    <span>{{ selectedDate | date : "MMMM dd, YYYY" }}</span>
  </kendo-gridlayout-item> -->
  <kendo-gridlayout-item class="mb-0 navbar-view-selection" [colSpan]="3">
    <span>View By:</span>
    <kendo-dropdownlist [data]="calendarViews" (valueChange)="onCalendarViewChanges($event)" textField="text"
      valueField="value" [valuePrimitive]="true" fillMode="outline" [value]="selectedViewIndex"
      [popupSettings]="{appendTo: 'component', animate: false}">
      <ng-template kendoDropDownListValueTemplate let-dataItem>
        <span *ngIf="dataItem?.text" class="not-placeholder"> {{ dataItem.text }} </span>
      </ng-template>
    </kendo-dropdownlist>
    <ng-container *ngIf="investigation?.id && (investigationScheduledDateUpdateOwn?.enabled || investigationScheduledDateUpdateAll?.enabled)">
      <button kendoButton themeColor="primary" fillMode="solid" iconClass="fa-solid fa-plus" class="ml-12" (click)="nextSchedule('NEW_SCHEDULE_CALENDAR')">Create New On
        Scene Exam Investigation</button>
    </ng-container>
  </kendo-gridlayout-item>
</kendo-gridlayout>
