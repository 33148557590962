import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { SharedModule } from "src/app/shared/shared.module";
import {
  InvestigationDetailComponent,
  InvestigationDocumentsComponent,
  InvestigationDocumentsSidebarComponent,
  InvestigationEditComponent,
  InvestigationEvidenceComponent,
  InvestigationEvidenceSidebarComponent,
  InvestigationFindInvestigatorModalKendoComponent,
  InvestigationManagementComponent,
  InvestigationRequestsComponent,
  InvestigationServicesComponent,
  InvestigationServicesHeaderComponent,
  InvestigationServicesSidebarComponent,
  InvestigationTimeAndExpModalKendoComponent
} from './';
import { InvestigationAddServiceModalComponent } from './investigation-add-service-modal/investigation-add-service-modal.component';
import { InvestigationAssignmentsContainerComponent } from './investigation-assignments-container/investigation-assignments-container.component';
import { InvestigationAssignmentsTableComponent } from './investigation-assignments-table/investigation-assignments-table.component';
import { InvestigationHistoryModalComponent } from './investigation-history-modal/investigation-history-modal.component';
import { InvestigationClientComponent } from './investigation-client/investigation-client.component';
import { InvestigationCreateComponent } from './investigation-create/investigation-create.component';
import { InvestigationClientReviewModalComponent } from './investigation-client-review-modal/investigation-client-review-modal.component';
import { InvestigationDetailsSidebarComponent } from './investigation-details-sidebar/investigation-details-sidebar.component';
import { InvestigationDownloadDocumentsModalComponent } from './investigation-download-documents-modal/investigation-download-documents-modal.component';
import { InvestigationDownloadPhotosModalComponent } from './investigation-download-photos-modal/investigation-download-photos-modal.component';
import { InvestigationDownloadShareFilesModalComponent } from './investigation-download-share-files-modal/investigation-download-share-files-modal.component';
import { InvestigationEvidenceTransferModalComponent } from './investigation-evidence-transfer-modal/investigation-evidence-transfer-modal.component';
import { InvestigationFindInvestigatorResultsComponent } from './investigation-find-investigator-results/investigation-find-investigator-results.component';
import { InvestigationListComponent } from './investigation-list/investigation-list.component';
import { InvestigationLossAddressCheckModalComponent } from './investigation-loss-address-check-modal/investigation-loss-address-check-modal.component';
import { InvestigationLossAddressCheckTableComponent } from './investigation-loss-address-check-table/investigation-loss-address-check-table.component';
import { InvestigationPartyComponent } from './investigation-party-component/investigation-party.component';
import { InvestigationPhotosComponent } from './investigation-photos/investigation-photos.component';
import { InvestigationPrioritiesComponent } from './investigation-priorities/investigation-priorities.component';
import { InvestigationRemoveServiceModalComponent } from './investigation-remove-service-modal/investigation-remove-service-modal.component';
import { InvestigationReportO365LaunchComponent } from './investigation-report-o365-launch/investigation-report-o365-launch.component';
import { InvestigationRequestModalComponent } from './investigation-request-modal/investigation-request-modal.component';
import { InvestigationSelectContactCompanyModalComponent } from './investigation-select-contact-company-modal/investigation-select-contact-company-modal.component';
import { InvestigationTimeAndExpQuickbooksModalComponent } from './investigation-time-and-exp-quickbooks-modal/investigation-time-and-exp-quickbooks-modal.component';
import { InvestigationTimeAndExpComponent } from './investigation-time-and-exp/investigation-time-and-exp.component';
import { InvestigationVehicleComponent } from './investigation-vehicle/investigation-vehicle.component';
import { InvestigationEvidencePhotosModalComponent } from './investigation-evidence-photos-modal/investigation-evidence-photos-modal.component';
import { InvestigationPhotosModalComponent } from './investigation-photos-modal/investigation-photos-modal.component';
import { InvestigationDownloadPhotosUrlModalComponent } from './investigation-download-photos-url-modal/investigation-download-photos-url-modal.component';
import { RejectReactivateModalComponent } from './reject-reactivate-modal/reject-reactivate-modal.component';
import { InvestigationInsuredPartyModalComponent } from './investigation-insured-party-modal/investigation-insured-party-modal.component';
import { OutstandingAdminComponent } from './investigation-time-and-exp/outstanding-admin/outstanding-admin.component';
import { EvidenceTableComponent } from './investigation-time-and-exp/evidence-table/evidence-table.component';
import { InvestigationEditScheduleDateComponent } from './investigation-edit-schedule-date/investigation-edit-schedule-date.component';
import { MarkasSentComponent } from './investigation-documents/markas-sent/markas-sent.component';
import { InvestigationPrioritiesKendoComponent } from './investigation-priorities-kendo/investigation-priorities-kendo.component';
import { InvestigationHistoryKendoModalComponent } from './investigation-history-kendo-modal/investigation-history-kendo-modal.component';
import { InvestigationEditInsuredContactedComponent } from './investigation-edit-insured-contacted/investigation-edit-insured-contacted.component';

@NgModule({
  declarations: [
    InvestigationManagementComponent,
    InvestigationDetailComponent,
    InvestigationServicesHeaderComponent,
    InvestigationServicesComponent,
    InvestigationRequestsComponent,
    InvestigationServicesSidebarComponent,
    InvestigationEvidenceComponent,
    InvestigationEvidenceSidebarComponent,
    InvestigationDocumentsComponent,
    InvestigationServicesSidebarComponent,
    InvestigationServicesSidebarComponent,
    InvestigationDocumentsSidebarComponent,
    InvestigationListComponent,
    InvestigationTimeAndExpComponent,
    InvestigationDocumentsSidebarComponent,
    InvestigationTimeAndExpModalKendoComponent,
    InvestigationTimeAndExpQuickbooksModalComponent,
    InvestigationCreateComponent,
    InvestigationEditComponent,
    InvestigationDetailsSidebarComponent,
    InvestigationDownloadPhotosModalComponent,
    InvestigationDownloadDocumentsModalComponent,
    InvestigationDownloadShareFilesModalComponent,
    InvestigationLossAddressCheckModalComponent,
    InvestigationRemoveServiceModalComponent,
    InvestigationRequestModalComponent,
    InvestigationClientReviewModalComponent,
    InvestigationHistoryModalComponent,
    InvestigationSelectContactCompanyModalComponent,
    InvestigationAddServiceModalComponent,
    InvestigationLossAddressCheckTableComponent,
    InvestigationPrioritiesComponent,
    InvestigationFindInvestigatorResultsComponent,
    InvestigationAssignmentsTableComponent,
    InvestigationAssignmentsContainerComponent,
    InvestigationEvidenceTransferModalComponent,
    InvestigationPhotosComponent,
    InvestigationReportO365LaunchComponent,
    InvestigationClientComponent,
    InvestigationPartyComponent,
    InvestigationVehicleComponent,
    InvestigationEvidencePhotosModalComponent,
    InvestigationPhotosModalComponent,
    InvestigationDownloadPhotosUrlModalComponent,
    RejectReactivateModalComponent,
    InvestigationInsuredPartyModalComponent,
    OutstandingAdminComponent,
    EvidenceTableComponent,
    InvestigationFindInvestigatorModalKendoComponent,
    InvestigationEditScheduleDateComponent,
    MarkasSentComponent,
    InvestigationPrioritiesKendoComponent,
    InvestigationHistoryKendoModalComponent,
    InvestigationEditInsuredContactedComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DragDropModule,
    MatGridListModule
  ],
  exports: [
    DragDropModule,
    MatGridListModule
  ]
})
export class InvestigationsModule { }
