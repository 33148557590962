import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../../helpers.class";

const nodeFragment = `
  id
  status
  source
  scheduledDate
  Investigation {
    id
    nefcoNumber
    timezone
  }
  User {
    id
    lastName
    firstName
    twilioPhoneNumber
    timezone
  }
  CreatedBy {
    id
    lastName
    firstName
  }
  LatestInvestigationOnScene {
    id
    status
  }
  Investigation {
    id
    nefcoNumber
    InvestigationAddress {
      address1
      address2
      address3
      city
      state
      postal
      country
    }
  }
  createdAt
`;

export const investigationOnSceneConnectionQuery = gql`${buildConnectionQuery("InvestigationOnScene", "investigationOnSceneConnection", nodeFragment)}`;

export const changeOnSceneStatusMutation = gql`mutation ChangeOnSceneStatus($input: ChangeOnSceneStatusInput!) {
    changeOnSceneStatus(input: $input)
}`;

export const UpdateInvestigationOnSceneScheduledDateMutation =  gql`mutation updateInvestigationOnSceneScheduledDate($input: UpdateInvestigationOnSceneScheduledDate!) {
  updateInvestigationOnSceneScheduledDate(input: $input)
}`;
