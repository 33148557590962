<div [dialogRef]="dialog" (appClickOutside)="close()">
  <div [appEnforcePermission]="authConfig.investigationScheduledDateUpdateOwn"
    #investigationScheduledDateUpdateOwn="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.investigationScheduledDateUpdateAll"
    #investigationScheduledDateUpdateAll="appEnforcePermission"></div>
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span>Schedule On Scene Investigation Date</span>
      <p class="modal-subtitle mb-12 mt-12">Investigation ID: {{investigation?.nefcoNumber}}</p>
      <p class="modal-subtitle mb-12">Timezone: {{(investigation?.timezone | timezonePipe) || '-'}}</p>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body">
    <p class="warning-message mb-16 mt-16">
      Enter the date and time of when the insured was first contacted.
    </p>
    <form #frm="ngForm" autocomplete="off">
      <section>
        <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }"
          [cols]="investigationScheduledDateUpdateAll?.enabled ? ['repeat(2,minmax(0,1fr))'] : ['repeat(1,minmax(0,1fr))'] ">
          <kendo-gridlayout-item *ngIf="investigationScheduledDateUpdateAll?.enabled">
            <kendo-formfield>
              <kendo-label class="asterisk" text="Staff Member"></kendo-label>
              <kendo-dropdownlist [name]="'staffMemberSelect'" #staffMemberSelect="ngModel" [data]="staffList"
                textField="userName" valueField="userId" fillMode="outline"
                [defaultItem]="{userName:  'Select staff member', userId: null}" [valuePrimitive]="true"
                [(ngModel)]="model.staff" [popupSettings]="{
                  appendTo: 'component',
                  animate: false,
                  width: 250
                }" required>
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span *ngIf="dataItem?.userName === 'Select staff member'" class="placeholder">
                    {{ dataItem?.userName }}
                  </span>
                  <span *ngIf="dataItem?.userName !== 'Select staff member'" class="not-placeholder">
                    {{ dataItem?.userName }}
                  </span>
                </ng-template>
              </kendo-dropdownlist>
              <kendo-formerror
                *ngIf="!staffMemberSelect?.valid && (staffMemberSelect?.dirty || staffMemberSelect?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="staffMemberSelect?.errors?.required">
                  Staff member cannot be left blank
                </span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label class="asterisk" text="Contact Date"></kendo-label>
              <kendo-datetimepicker calendarType="classic" [fillMode]="'outline'" name="contactedDate"
                #contactedDate="ngModel" [(ngModel)]="model.contactedDate" placeholder="Enter contact date"
                format="MM-dd-yyyy hh:mm a" formatPlaceholder="formatPattern" [max]="currentDate" [min]="receivedDate"
                required></kendo-datetimepicker>
              <kendo-formerror>
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="!contactedDate?.valid && contactedDate.errors.required && (contactedDate?.dirty || contactedDate?.touched)">Date cannot be
                  left
                  blank</span>
                  <span *ngIf="!contactedDate?.valid && contactedDate.errors.maxError && (contactedDate?.dirty || contactedDate?.touched)">Please select a date that is not in the future.</span>
                  <span *ngIf="!contactedDate?.valid && contactedDate.errors.minError && (contactedDate?.dirty || contactedDate?.touched)">Please select a date that is not before received date.</span>
              </kendo-formerror>
            </kendo-formfield>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </section>
    </form>
    <kendo-dialog-actions class="schedule-action" layout="end">
      <div>
        <button kendoButton [size]="'medium'" themeColor="light" class="kendo-btn" class="mr-16" (click)="close()">
          Cancel
        </button>
        <button kendoButton class="kendo-btn" [disabled]="!frm?.valid" [size]="'medium'" themeColor="primary"
          (click)="submit()">
          Submit
        </button>
      </div>
    </kendo-dialog-actions>
  </div>
