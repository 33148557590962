import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform, Injectable } from '@angular/core';
import moment from 'moment';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const appDateTransform = (isoDate: string, transformFormat: string = 'MMMM D, YYYY', inputFormat: string = 'YYYY-MM-DDTHH:mm:ssZ') => isoDate ? moment(isoDate, [inputFormat]).format(transformFormat) : '';

const appTimeTransform = (isoDate, transformFormat: string = 'HH:mm:ss', inputFormat: string = 'h:mm A') => appDateTransform(isoDate, transformFormat, inputFormat);

@Injectable()
@Pipe({ name: 'nullableDate' })
export class NullableDatePipe implements PipeTransform {
  transform(isoDate: string, transformFormat?: string, inputFormat?: string): string {
    return appDateTransform(isoDate, transformFormat, inputFormat);
  }
}

@Injectable()
@Pipe({ name: 'nullableTime' })
export class NullableTimePipe implements PipeTransform {
  transform(time: string, transformFormat?: string, inputFormat?: string): string {
    return appTimeTransform(time, transformFormat, inputFormat);
  }
}

@Injectable()
@Pipe({ name: 'customDate' })
export class CustomDatePipe implements PipeTransform {
  // Use the same formats needed for the Angular date pipe
  transform(date: string, format: string, isUTC = false): string {
    if (isUTC) {
      return new DatePipe('en-US').transform(date, format, 'UTC');
    } else {
      return new DatePipe('en-US').transform(date, format);
    }
  }
}
@Injectable()
@Pipe({ name: 'dateWithTimezone' })
export class DateWithTimezonePipe implements PipeTransform {
  transform(value: string | Date, format: string = 'MMMM D, YYYY', timeZone: string = 'UTC'): string {
    if (!value) {
      return '';
    }
    const date = dayjs(value).tz(timeZone);
    return date.format(format);
  }
}

@Injectable()
@Pipe({ name: 'timezonePipe' })
export class TimeZonePipe implements PipeTransform {
  transform(value: string): string {
    const date = new Date();

    // Create an Intl.DateTimeFormat object for the specified timezone
    const options: any = { timeZone: value, timeZoneName: 'longOffset' };
    const formatter = new Intl.DateTimeFormat('en-US', options);

    // Format the date using the formatter
    const parts = formatter.formatToParts(date);
    const gmtOffset = parts.find(part => part.type === 'timeZoneName').value;

    // Map timezones to descriptive names
    const timeZoneDescriptions = {
      "Pacific/Honolulu": "Hawaii-Aleutian Time - Honolulu",
      "America/Anchorage": "Alaska Time - Anchorage",
      "America/Los_Angeles": "Pacific Time - Los Angeles",
      "America/Phoenix": "Mountain Time - Phoenix",
      "America/Denver": "Mountain Time - Denver",
      "America/Chicago": "Central Time - Chicago",
      "America/New_York": "Eastern Time - New York",
      "America/Indiana/Indianapolis": "Eastern Time - Indianapolis"
    };

    const timeZoneDescription = timeZoneDescriptions[value] || "Unknown Timezone";

    // Return the formatted string
    return `(${gmtOffset}) ${timeZoneDescription}`;
  }
}
